import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {GET_CHALLENGES} from "../redux/actions/challenge-actions";
import StorySelector from "../components/StoriesBuilder/StorySelector";
import SearchSeriesInput from "../components/StoriesBuilder/SearchSeriesInput";
import Editor from '@monaco-editor/react';
import {JSONToHTML} from "html-to-json-parser";
import Story from "../components/StoriesBuilder/Story";

const StoriesBuilderContainer = () => {

    const [stories, setStories] = useState([])
    const [selectedStory, setSelectedStory] = useState(null)
    const [defaultEditorValue, setDefaultEditorValue] = useState('')

    const [stringForRender, setStringForRender] = useState('')

    const editorRef = useRef(null);
    const storiesContainerRef = useRef(null)

    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
    }

    function handleEditorChange(value, event) {
        setDefaultEditorValue(value)
    }

    useEffect(()=>{
        if(selectedStory){
            JSONToHTML(selectedStory.config, true).then(result=> {
                setDefaultEditorValue(result)
            })
        }
    }, [selectedStory])

    useEffect(()=>{
        if(defaultEditorValue){
            setStringForRender(defaultEditorValue)
            setTimeout(function () {
                editorRef.current
                    .getAction('editor.action.formatDocument')
                    .run()
                    .then();
            }, 500);
        }
    },[defaultEditorValue])

    return (
        <div className="flex flex-col drawer-content space-y-5">
            <div className={"flex flex-row justify-start items-center mb-2 space-x-3"}>
                <SearchSeriesInput setStories={setStories} />
                <StorySelector
                    stories={stories}
                    selectedStory={selectedStory}
                    setSelectedStory={setSelectedStory}
                />
            </div>
            <div className={"flex flex-row h-screen w-full border-2 border-black space-x-1"}>
                <div className={"w-11/12"}>
                    <Editor
                        theme={"vs-dark"}
                        defaultLanguage="html"
                        value={defaultEditorValue}
                        onMount={handleEditorDidMount}
                        onChange={handleEditorChange}
                    />
                </div>
                <div className={"flex flex-row justify-center w-1/2"}>
                    <div className={"mt-10"}>
                        <div className="mockup-phone">
                            <div className="camera"></div>
                            <div className="display">
                                <div ref={storiesContainerRef} className="artboard artboard-demo phone-1">
                                    <Story key={`${stringForRender}-${Math.random()}`} content={stringForRender} storyBackground={"bg-mint"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

StoriesBuilderContainer.propTypes = {
    challenges: PropTypes.array,
    loadingChallenges: PropTypes.bool,
    getChallenges: PropTypes.func
}

const mapStateToProps = (state) => ({
    challenges: state.challenge.challenges
})


const mapDispatchToProps = (dispatch) => ({
    getChallenges: () => dispatch({ type: GET_CHALLENGES })
})

export default connect(mapStateToProps, mapDispatchToProps)(StoriesBuilderContainer)
