import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ActionTile from "./ActionTile";

const ChallengeCard = ({ challenge }) => {

    const [actionsWithManual, setActionsWithManual] = useState([])

    useEffect(()=>{
        if(challenge){
            const actions = challenge.actions.filter(action => action.manual === true)
            if(actions !== undefined) {
                setActionsWithManual(actions)
            }

        }
    }, [challenge])

    console.log(actionsWithManual)

    return (
        <div className="card w-full sm:w-96 bg-base-100 shadow-sm border-2">
            <div className="card-body">
                <h2 className="card-title">{challenge.title}</h2>
                <p>{challenge.description}</p>
                {actionsWithManual.length > 0 && <div className={"divider mt-2 mb-0"} />}
                <div className={"divide-y-2"}>
                    {actionsWithManual.length > 0 && actionsWithManual.map(action => {
                        return <ActionTile key={action.id} action={action} />
                    })}
                </div>
            </div>
        </div>
    );
}

ChallengeCard.propTypes = {
}

const mapStateToProps = (state) => ({
})


const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeCard)
