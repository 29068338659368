import React from "react";
import StudentsTable from "./StudentsTable";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Papa from 'papaparse'

import {
    ENROLL_STUDENTS,
    GET_SPRINT_STUDENTS,
    GET_STUDENTS_FOR_ENROLL,
} from "../../../../redux/actions/sprint-students-actions";
import {WRITE_OFF_BALANCES} from "../../../../redux/actions/students-actions";
import ProgressBar from "../../../elements/ProgressBar";
import {SHOW_MODAL} from "../../../../redux/actions/common-actions";
import Loading from "../../../Loading";
import Pagination from "../../../elements/pagination/Pagination";
import sprintStudentsApi from "../../../../redux/api/sprint-students-api";


class StudentsTab extends React.Component {
    constructor(props) {
        super(props);
        this.childRef = React.createRef();

        this.state = {
            searchValue: '',
            generateCsv: false
        }

    }

    onPageChanged = data => {
        const { currentPage, pageLimit } = data;
        this.getStudentsWithParam(currentPage, pageLimit)
    }

    getStudentsWithParam(pageNumber, pageLimit) {

        const payload = {
            isFilter: false,
            query: {
                'page_size': pageLimit,
                'page': pageNumber
            }
        }

        if(this.state.searchValue) {
            payload.query.instagram = this.state.searchValue
            payload.isFilter = true
        }

        if (this.props.currentSprint.enrolled) {
            payload.query['sprint_id'] = this.props.currentSprint.id
            this.props.getSprintStudents(payload)
        } else {
            this.props.getStudentsForEnroll(payload)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!prevProps.currentSprint.enrolled && this.props.currentSprint.enrolled) || (!prevProps.currentSprint.writeOff && this.props.currentSprint.writeOff)) {
            this.childRef.current.gotoPage(1)
            // this.getStudentsWithParam(1, this.props.pageSize)
        }
    }

    enrollStudents = () => {
        this.props.enrollStudents({
            sprint: this.props.currentSprint.id,
        })
    }

    writeOff = () => {
        if (this.props.currentSprint.enrolled) {
            this.props.writeOffBalances({
                sprintId: this.props.currentSprint.id,
            })
        }
    }

    openAddStudentModal(modalType) {
        this.props.showModal(modalType)
    }

    dataFromListOfUsersState = () => {
        return this.state.csvData;
    }

    setCsvData(students){
        const data = students.map(student => {
            return {
                instagram: student.profile.instagram,
                balance: student.balance.weeks,
                'payment date': student.balance.lastPaymentDate
            }
        })

        const csv = Papa.unparse(data)
        const fileName = 'sprint_students.csv'

        let csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        let csvURL =  null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, fileName);
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', fileName);
        tempLink.click();

        this.setState({ generateCsv: false })
    }

    getCsvData = () => {

        this.setState({
            generateCsv: true
        })

        const query= {
            'page_size': this.props.totalRecords,
            'page': 1,
        }


        if (this.props.currentSprint.enrolled) {
            query['sprint_id'] = this.props.currentSprint.id
            sprintStudentsApi.getStudentsBySprints(query).then(response=>{
                const accounts = {
                    accounts: response.data.results.map(student=>{
                        return student.account
                    })
                }
                sprintStudentsApi.getAccountsByIds(accounts).then(response=>{
                    this.setCsvData(response.data)
                })
            }).catch(err => {
                this.setState({
                    generateCsv: false
                })
            })
        } else {
            sprintStudentsApi.getStudentsForEnroll(query).then(response=>{
                this.setCsvData(response.data.results)
            }).catch(err => {
                this.setState({
                    generateCsv: false
                })
            })
        }
    }

    handleSearchValue = (event) => {
        this.setState({
            searchValue: event.target.value
        })
    }

    searchFetch = () => {
        this.getStudentsWithParam(1, this.props.pageSize)
    }

    render(){

        const enrolled = this.props.currentSprint.enrolled
        const writeOff = this.props.currentSprint.writeOff
        const progress = this.props.writeOfProgress

        return (
            <React.Fragment>
                <div className={ `${this.props.tab === 'tab-2' ? '' : 'hidden'} mt-5` }>
                    <div className={"flex flex-row justify-between items-start"}>
                        <div className={"flex flex-row flex-wrap items-end"}>
                            <button
                                className={`btn btn-primary btn-xs mb-2 mr-2 sm:mr-4 ${this.props.enrolled ? 'btn-disabled' : ''}`}
                                onClick={this.enrollStudents}
                            >
                                {this.props.loadingEnrollStudents && <Loading size={"xs"} />}
                                Enroll students
                            </button>
                            <button
                                className={`btn btn-primary btn-xs mb-2 mr-2 sm:mr-4 ${(enrolled && writeOff) || (!enrolled && !writeOff) ? 'btn-disabled' : ''}`}
                                onClick={this.writeOff}
                            >
                                Write off
                            </button>
                            <button
                                className={`btn btn-primary btn-xs mb-2 mr-2 sm:mr-4 ${!enrolled ? 'btn-disabled' : ''}`}
                                onClick={() => this.openAddStudentModal('enrollStudentInSprint')}
                            >
                                Add student
                            </button>
                        </div>
                        <button
                            className={"btn btn-primary btn-xs mb-2"}
                            onClick={this.getCsvData}
                        >
                            {this.state.generateCsv && <Loading size={'xs'} />}
                            Download Csv
                        </button>
                    </div>
                    {progress?.progress && !progress?.complete && (
                        <ProgressBar value={progress.progress?.percent} />
                    )}
                    <div className={"flex flex-row justify-start items-end my-3"}>
                        <div className="form-control">
                            <div className="input-group">
                                <input
                                    type="text"
                                    placeholder="Search…"
                                    className="input input-bordered input-sm"
                                    onChange={this.handleSearchValue}

                                />
                                <button
                                    className={`btn btn-square btn-sm`}
                                    onClick={this.searchFetch}

                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <StudentsTable sprint={this.props.currentSprint} />
                    <div className="flex justify-center mt-4">
                        <Pagination
                            ref={this.childRef}
                            totalRecords={this.props.totalRecords}
                            pageLimit={this.props.pageSize}
                            pageNeighbours={1}
                            onPageChanged={this.onPageChanged}
                        />
                    </div>
                </div>
            </React.Fragment>

        );
    }

}

StudentsTab.propTypes = {
    tab: PropTypes.string,
    enrollStudents: PropTypes.func.isRequired,
    loadingEnrollStudents: PropTypes.bool,
    writeOffBalances: PropTypes.func.isRequired,
    writeOfProgress: PropTypes.object,
    students: PropTypes.array,
    currentSprint: PropTypes.object,
    enrolled: PropTypes.bool,
    showModal: PropTypes.func
}

const mapStateToProps = (state) => ({
    students: state.sprintStudents.students,
    loadingEnrollStudents: state.sprintStudents.loadingEnrollStudents,
    currentSprint: state.sprint.currentSprint,
    writeOfProgress: state.students.writeOfProgress,
    totalRecords: state.pagination.studentsTotalRecords,
    pageSize: state.pagination.studentsPageSize
})


const mapDispatchToProps = (dispatch) => ({
    enrollStudents: (students) => dispatch({ type: ENROLL_STUDENTS, payload: students }),
    writeOffBalances: (accounts) => dispatch({ type: WRITE_OFF_BALANCES, payload: accounts }),
    getStudentsForEnroll: (payload) => dispatch({ type: GET_STUDENTS_FOR_ENROLL, payload: payload }),
    getSprintStudents: (payload) => dispatch({ type: GET_SPRINT_STUDENTS, payload: payload }),
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentsTab)
