import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import shortid from "shortid";
import {GET_SERIES} from "../../redux/actions/stories-actions";

const SearchSeriesInput = ({ series, getSeries, setStories }) => {

    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchResult, setSearchResult] = useState([])

    const dropdownRef = useRef(null);

    const handleChange = e => {
        setSearchValue(e.target.value);
        if (!visible && e.target.value.length > 2) {
            setVisible(true);
        } else if (visible && e.target.value.length < 3) {
            setVisible(false)
        }
    };

    useEffect(()=>{
        if(searchValue.length > 3){
            searchFetch(searchValue)
        }

        if(searchValue.length === 0 && !visible) {
            setStories([])
        }
    },[searchValue])

    const searchFetch = (value) => {

        const query = {
            'search': value,
            'page_size': 10,
            'page': 1
        }

        getSeries(query)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => document.removeEventListener('mousedown', handleClick, false);
    }, []);

    const handleSelectItem = item => {
        setSearchValue(item.name);
        setStories(item.stories)
        setVisible(false);
    };

    const handleClick = e => {
        if (dropdownRef.current.contains(e.target)) {
            return;
        }
        setVisible(false);
    };

    return (
        <div ref={dropdownRef}
             className={`dropdown dropdown-bottom dropdown-end ${visible ? 'dropdown-open' : ''}`}>
            <div className="label">
                <span className="label-text">Series</span>
            </div>
            <input
                className="input input-bordered input-sm"
                type="text"
                placeholder={"Search series"}
                value={searchValue}
                onChange={handleChange}
                onFocus={() => {
                }}
            />

            {visible && (
                <ul className={"mt-2 w-full p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box"}>
                    {series &&
                        <li className={""}>
                            {
                                series.length > 0 ? (
                                    series.map((x, index) => {
                                        if (index < 10) {
                                            return <div
                                                key={shortid.generate()}
                                                className={"text-xs"}
                                                onClick={() => handleSelectItem(x)}
                                            >
                                                {x.name}
                                            </div>
                                        } else if (index === 10) {
                                            return <div key={shortid.generate()}>...</div>
                                        } else return null
                                    })
                                ) : (
                                    <div key={shortid.generate()}>{"No results"}</div>
                                )
                            }
                        </li>
                    }
                </ul>
            )}
        </div>
    );
};

SearchSeriesInput.propTypes = {
    series: PropTypes.array,
    getSeries: PropTypes.func,
    setStories: PropTypes.func
}

const mapStateToProps = (state) => ({
    series: state.stories.series
})

const mapDispatchToProps = (dispatch) => ({
    getSeries: (query) => dispatch({ type: GET_SERIES, payload: query })
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchSeriesInput)