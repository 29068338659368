import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import PropTypes from "prop-types";

import {
    GET_ANSWERS_BY_HOMEWORK,
} from "../redux/actions/homework-actions";

import Stat from "../components/elements/Stat";
import PaginationBack from "../components/elements/pagination/Pagination";
import AnswerTable from "../components/Homework/AnswerTable";

const HomeworkContainer = ({ answers, currentSprint, currentHomework, getAnswersByHomework }) => {

    useEffect(() => {
        getAnswersByHomework(currentHomework.id)
    }, []);

    return (
        <div className="flex flex-col drawer-content">
            <h1 className="prose-2xl mb-2">Stat</h1>
            <div className="w-full shadow stats mb-10">
                <Stat title={"Completed"} number={answers.length} desc={"students"} />
                <Stat />
                <Stat />
            </div>
            <div className={"flex flex-row items-center"}>
                <div className={"flex flex-row justify-between items-center"}>
                    <div className="text-sm breadcrumbs mb-5">
                        <ul>
                            <li>
                                <Link to={"/sprints"}>Sprints</Link>
                            </li>
                            <li>
                                <Link to={`/sprints/${currentSprint.id}`}>{currentSprint.title}</Link>
                            </li>
                            <li>{currentHomework.title}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="shadow">
                <AnswerTable />
            </div>
            <div className="flex flex-grow-1 justify-center mt-4">
                <PaginationBack />
            </div>
        </div>
    );
}

HomeworkContainer.propTypes = {
    answers: PropTypes.array,
    currentHomework: PropTypes.object,
    currentSprintInfo: PropTypes.object,
    getAnswersByHomework: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    answers: state.homework.answers,
    currentSprint: state.sprint.currentSprint,
    currentHomework: state.homework.currentHomework
})


const mapDispatchToProps = (dispatch) => ({
    getAnswersByHomework: (homeworkId) => dispatch({ type: GET_ANSWERS_BY_HOMEWORK, payload: homeworkId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeworkContainer)
