import React, {useEffect, useState} from 'react'
import 'react-edit-text/dist/index.css';
import {FormProvider} from "react-hook-form";
import BackgroundButton from "./BackgroundButton";
import TextEditor from "./TextEditor";
import SaveButton from "./SaveButton";
import PublishButton from "./PublishButton";
import Media from "./Media";

import PropTypes from "prop-types";

import {connect} from "react-redux";
import {withUseFormHook} from "../../helpers/with-form-hook";
import {
    SET_EMPTY_HOMEWORK_CONFIG,
    SET_HOMEWORK_CONFIG,
    SET_HOMEWORK_CONFIG_ASSIGNMENT,
} from "../../redux/actions/configurator-actions";
import Loading from "../Loading";
import {SET_CURRENT_HOMEWORK} from "../../redux/actions/homework-actions";

const Configurator = ({
                          currentHomework,
                          setHomeworkConfigAssignment,
                          setCurrentHomework,
                          setEmptyHomeworkConfig,
                          form,
                          setHomeworkConfig,
                          config,
                          loadingGetHomeworkConfig,
                          createUpdateHomeworkConfigError
}) => {

    const methods = form

    const [title, setTitle] = useState(currentHomework.title)

    const cleanComponent = () => {

        methods.reset({})
        setHomeworkConfigAssignment([])
        setCurrentHomework({})
        setEmptyHomeworkConfig()
    }

    const taskTypes = {
        fill: 'Fill in the gaps',
        voice: 'Voice',
        quizlet: 'Quizlet'
    }

    const taskOptions = Object.entries(taskTypes).map(([key,value])=>{
        return (
            <option key={key} value={key}>{value.toString()}</option>
        );
    })

    const quizletTypes = {
        match: 'Match',
        learn: 'Learn',
        test: 'Test',
        flashcards: 'Flashcards',
        spell: 'Spell'
    }

    const quizletOptions = Object.entries(quizletTypes).map(([key,value])=>{
        return (
            <option key={key} value={key}>{value.toString()}</option>
        );
    })

    const defaultQuizletOption = 'flashcards'

    let loading = loadingGetHomeworkConfig
    let error = createUpdateHomeworkConfigError

    const handleChange = (event, reset) => {
        setHomeworkConfig({withMedia: event.target.checked})

        if(event.target.checked === true){
            if(!config.isVideo && !this.props.config.isImage){
                setHomeworkConfig({
                    isImage: false,
                    isVideo: true
                })
            }
        }
        if(event.target.checked === false){
            setHomeworkConfig({
                videoLink: null,
                image: null,
                isImage: false,
                isVideo: false,
                startTimeMin: 0,
                startTimeSec: 0,
                startTime: 0,
                endTimeMin: 0,
                endTimeSec: 0,
                endTime: 0
            })

            reset({
                videoLink: null,
                image: null,
                isImage: false,
                isVideo: false,
                startTimeMin: 0,
                startTimeSec: 0,
                startTime: 0,
                endTimeMin: 0,
                endTimeSec: 0,
                endTime: 0,
            })
        }
    }

    useEffect(()=>{

        return () => {
            cleanComponent()
        }
    }, [])

    useEffect(()=>{

        if (config.type === 'quizlet') {
            setHomeworkConfig({quizletType: defaultQuizletOption})
        }

        if (config.type !== 'quizlet' && quizletTypes !== null) {
            setHomeworkConfig({quizletType: null})
        }

    }, [config.type])

    useEffect(()=>{

        if(config){
            methods.reset({
                videoLink: config.videoLink,
                quizletId: config.quizletId,
                quizletType: config.quizletType,
                image: config.image,
                isImage: config.isImage,
                isVideo: config.isVideo,
                isQuizlet: config.isQuizlet,
                startTimeMin: config.startTimeMin,
                startTimeSec: config.startTimeSec,
                startTime: config.startTime,
                endTimeMin: config.endTimeMin,
                endTimeSec: config.endTimeSec,
                endTime: config.endTime,
                taskTitle: config.taskTitle
            })
        }
    }, [config])

    return (
        <React.Fragment>
            {loading ? (
                <div className="flex flex-col flex-1 lg:max-w-laptop justify-center items-center h-screen bg-brandYellow">
                    <Loading size="lg"/>
                </div>
            ) : (
                <div className={"mx-6"}>
                    <div className={"flex flex-row justify-between"}>
                        <h1 className={"my-5 text-2xl"}>Configurator</h1>
                        {currentHomework.status === 'published' ? (
                            <h1 className={"my-5 text-2xl"}> Published </h1>
                        ) : (
                            <h1 className={"my-5 text-2xl"}> In Progress </h1>
                        )}
                    </div>
                    <FormProvider {...methods} >
                        <h1 className={"mb-3 text-lg opacity-50"}>Homework</h1>
                        <div className={"form-control"}>
                            <select
                                className="select select-bordered"
                                defaultValue={config.type}
                                name="type"
                                {...methods.register("type", {
                                    required: true,
                                    onChange: (event) => setHomeworkConfig({type:event.target.value})
                                })}
                            >
                                { taskOptions }
                            </select>
                            <label className="label">
                                {methods.formState.errors.type && <span className="label-text-alt">Choose homework type</span>}
                            </label>
                        </div>
                        <div className="form-control">
                            <input
                                className="input input-bordered"
                                type="text"
                                name="title"
                                placeholder="Title"
                                defaultValue={title}
                                {...methods.register("title", {
                                    required: true,
                                    onChange: (event) => setHomeworkConfig({title: event.target.value})
                                })}
                            />
                            <label className="label">
                                {methods.formState.errors.title && <span className="label-text-alt">Set homework title</span>}
                            </label>
                        </div>

                        <h1 className={"mb-3 text-lg opacity-50 border-b-2"}>Task</h1>

                        {config.type === 'quizlet' ? (
                            <div className={"mb-10"}>
                                <div className={"form-control"}>
                                    <select
                                        className="select select-bordered"
                                        defaultValue={defaultQuizletOption}
                                        name="quizletType"
                                        {...methods.register("quizletType", {
                                            required: true,
                                            onChange: (event) => setHomeworkConfig({quizletType:event.target.value}),
                                        })}
                                    >
                                        { quizletOptions }
                                    </select>
                                    <label className="label">
                                        {methods.formState.errors.quizletType && <span className="label-text-alt">Choose quizlet type</span>}
                                    </label>
                                </div>
                                <div className="form-control">
                                    <input
                                        className="input input-bordered"
                                        type="text"
                                        name="quizletId"
                                        placeholder="Quizlet ID"
                                        defaultValue={config.quizletId}
                                        {...methods.register("quizletId", {
                                            required: true,
                                            onChange: (event) => setHomeworkConfig({quizletId: event.target.value})
                                        })}
                                    />
                                    <label className="label">
                                        {methods.formState.errors.quizletId && <span className="label-text-alt">Set quizlet ID</span>}
                                    </label>
                                </div>
                                <div className="form-control">
                                    <input
                                        className="input input-bordered"
                                        type="text"
                                        name="quizletLink"
                                        placeholder="Quizlet link"
                                        defaultValue={config.quizletLink}
                                        {...methods.register("quizletLink", {
                                            required: true,
                                            onChange: (event) => setHomeworkConfig({quizletLink: event.target.value})
                                        })}
                                    />
                                    <label className="label">
                                        {methods.formState.errors.quizletLink && <span className="label-text-alt">Set quizlet link</span>}
                                    </label>
                                </div>
                            </div>
                        ) : null}

                        {config.type === 'fill' ? (
                            <>
                                <div className={`flex flex-row items-center justify-between w-full ${!config.withMedia ? "mb-10" : "mb-3" }`}>
                                    <h1>Media</h1>
                                    <label htmlFor="toggleB" className="flex items-center cursor-pointer">
                                        <div className="relative">
                                            <input
                                                defaultChecked={config.withMedia}
                                                type="checkbox"
                                                id="toggleB"
                                                name="withMedia"
                                                className="sr-only"
                                                {...methods.register("withMedia", {
                                                    onChange: (event) => {
                                                        handleChange(event, methods.reset)
                                                    }
                                                })} />
                                            <div className="block bg-gray-600 w-10 h-6 rounded-full" />
                                            <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition" />
                                        </div>
                                    </label>
                                </div>
                                {config.withMedia ? (
                                    <Media />
                                ) : null}
                            </>
                        ) : null}


                        {config.type !== 'quizlet' ? (
                            <>
                                <h1 className={"my-5"}>Background</h1>
                                <div className={"flex flex-row justify-start mb-10"}>
                                    <div className={"flex flex-row flex-1 items-center"} >
                                        <BackgroundButton buttonType="color" />
                                        <h2 className={"ml-3"}>Color</h2>
                                    </div>
                                    <div className={"flex flex-row flex-1 items-center"} >
                                        <BackgroundButton buttonType="gradient" />
                                        <h2 className={"ml-3"}>Gradient</h2>
                                    </div>
                                    <div className={"flex flex-row flex-1 items-center"}>
                                        <BackgroundButton buttonType="image" />
                                        <h2 className={"ml-3"}>Image</h2>
                                    </div>
                                </div>
                                <div className={"mb-10 space-y-4"}>
                                    <div className="form-control">
                                        <label className={"mb-4"}>Task title</label>
                                        <input
                                            className="input input-bordered"
                                            type="text"
                                            name="title"
                                            placeholder="Task title"
                                            defaultValue={config.taskTitle}
                                            {...methods.register("taskTitle", {
                                                required: true,
                                                onChange: (event) => setHomeworkConfig({taskTitle: event.target.value})
                                            })}
                                        />
                                        <label className="label">
                                            {methods.formState.errors.taskTitle && <span className="label-text-alt">Set task title</span>}
                                        </label>
                                    </div>
                                    <div className="form-control">
                                        <label className={"mb-4"}>Task body</label>
                                        <TextEditor />
                                    </div>
                                </div>

                            </>
                        ) : null}

                    </FormProvider>
                    <div className={"flex flex-row justify-between mb-5"}>
                        <SaveButton />
                        <PublishButton />
                    </div>
                    { error ? (
                        <div className="alert shadow-lg alert-error mb-5">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                     fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                                <span>Error! { error }</span>
                            </div>
                        </div>
                    ) : null }

                </div>
            )}

        </React.Fragment>
    );
}

Configurator.propTypes = {
    loadingGetHomeworkConfig: PropTypes.bool,
    createUpdateHomeworkConfigError: PropTypes.string,
    config: PropTypes.object,
    currentHomework: PropTypes.object,
    setHomeworkConfigSingle: PropTypes.func,
    setHomeworkConfigMany: PropTypes.func,
    form: PropTypes.object,
    setHomeworkConfig: PropTypes.func,
    setHomeworkConfigAssignment: PropTypes.func,
    setCurrentHomework: PropTypes.func,
    setEmptyHomeworkConfig: PropTypes.func
}

const mapStateToProps = (state) => ({
    loadingGetHomeworkConfig: state.config.loadingGetHomeworkConfig,
    createUpdateHomeworkConfigError: state.config.createUpdateHomeworkConfigError,
    config: state.config.config,
    currentHomework: state.homework.currentHomework
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfig: (params) => dispatch({ type: SET_HOMEWORK_CONFIG, payload: params }),
    setHomeworkConfigAssignment: (assignment) => dispatch({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: assignment }),
    setCurrentHomework: (currentHomework) => dispatch({ type: SET_CURRENT_HOMEWORK, payload: currentHomework }),
    setEmptyHomeworkConfig: () => dispatch({type: SET_EMPTY_HOMEWORK_CONFIG})
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(Configurator))
