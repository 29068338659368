import React, {useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {GET_SEND_ACTION_PROGRESS, SEND_ACTION_CODE} from "../../redux/actions/challenge-actions";
import ProgressBar from "../elements/ProgressBar";
import {usePrevious} from "../../helpers/usePrevious";
import challengeApi from "../../redux/api/challenge-api";
import Loading from "../Loading";

const ActionTile = ({ action, sendActionCode, sendingActionCode, calculateActionProgress }) => {

    const handleSendingActionCode = () => {
        sendActionCode({
            "withProgress": true,
            "data": {
                "actionCode": "ENROLLED",
                "actionCodeType": "IN_ROW"
            }
        })
    }

    return (
        <div className={"flex flex-col"}>
            <div className="flex justify-between items-center pl-2 py-3">
                <span className="text-base font-semibold">{action.type.title}</span>
                <button
                    onClick={handleSendingActionCode}
                    className={`btn btn-primary btn-xs shadow-brand-button rounded-full ${sendingActionCode ? 'loading' : ''}`}
                >
                    {sendingActionCode && <Loading size="xs" />}
                    Calculate
                </button>

            </div>
            {calculateActionProgress?.progress && !calculateActionProgress?.complete && (
                <ProgressBar value={calculateActionProgress.progress?.percent} />
            )}
        </div>

    );
}

ActionTile.propTypes = {
    sendActionCode: PropTypes.func,
    action: PropTypes.object,
    sendingActionCode: PropTypes.bool,
    calculateActionProgress: PropTypes.object,
}

const mapStateToProps = (state) => ({
    sendingActionCode: state.challenge.sendingActionCode,
    calculateActionProgress: state.challenge.calculateActionProgress
})


const mapDispatchToProps = (dispatch) => ({
    sendActionCode: (params) => dispatch({ type: SEND_ACTION_CODE, payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionTile)