import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
    HIDE_MODAL
} from "../../../redux/actions/common-actions";

import {withUseFormHook} from "../../../helpers/with-form-hook";
import Loading from "../../Loading";
import {AiOutlineSearch, AiOutlineUserAdd} from "react-icons/ai";
import {GET_STUDENTS, RESET_STUDENTS_DATA} from "../../../redux/actions/students-actions";
import {Link} from "react-router-dom";
import {ENROLL_STUDENT} from "../../../redux/actions/sprint-students-actions";

const EnrollStudentInSprint = ({
                                   loadingStudents,
                                   hideModal,
                                   getStudents,
                                   modal,
                                   students,
                                   resetStudentsData,
                                   sprintStudents,
                                   enrollStudent,
                                   currentSprint,
                                   loadingEnrollStudent
}) => {

    const modalStyle = modal ? 'modal modal-open' : "modal"
    const [searchValue, setSearchValue] = useState('')

    const searchFetch = () => {

        const query = {
            'search': searchValue,
            'is_student': true,
            'page_size': 10,
            'page': 1
        }

        getStudents(query)
    }

    const onCancel = (e) => {
        e.preventDefault()
        hideModal('enrollStudentInSprint')
    }

    const addStudent = (student) => {

        enrollStudent({
            studentId: student.uid,
            weeks: student.balance.weeks,
            sprintId: currentSprint.id,
            source: 'staff'
        })
    }

    useEffect(()=>{
        return () => {
            resetStudentsData();
        };
    }, [])

    return (
        <React.Fragment>
            <div className={modalStyle}>
                <div className="modal-box p-4">
                    <div className={"flex flex-row justify-between items-center mb-4"}>
                        <div className={"text-xl font-bold"}>Enroll the student in the sprint.</div>
                        <button
                            onClick={onCancel}
                            className="btn btn-sm btn-circle btn-ghost"
                        >
                            ✕
                        </button>
                    </div>
                    <div className="flex w-full join">
                        <div className={"w-full"}>
                            <input
                                className="w-full input input-bordered join-item"
                                placeholder="Search"
                                value={searchValue}
                                onChange={(event)=>setSearchValue(event.target.value)}
                            />
                        </div>
                        <button
                            className="btn btn-square join-item"
                            onClick={searchFetch}
                        >
                            {loadingStudents ? <Loading size="xs" /> : (
                                <AiOutlineSearch className={"h-7 w-7"}/>
                            )}
                        </button>
                    </div>
                    <div>
                        {students && (
                            students.length > 0 ? (
                                <div className="overflow-x-auto pt-4">
                                    <table className="table">
                                        {/* head */}
                                        <thead>
                                        <tr>
                                            <th>Instagram</th>
                                            <th>Balance</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {students?.map(student => {
                                            let index = sprintStudents.findIndex(sprintStudent => sprintStudent.uid === student.uid);
                                            const isEnrolled = index !== -1

                                            const Action = ({ isEnrolled, loadingEnrollStudent, student }) => {
                                                if(!isEnrolled) {
                                                    if(student.balance.weeks === 0) {
                                                        return <div className="text-sm opacity-50">Balance is 0</div>
                                                    } else if (student.profile.status === 'freeze') {
                                                        return <div className="text-sm opacity-50">Status is freeze</div>
                                                    } else {
                                                        return (
                                                            <button
                                                                className={`btn btn-ghost`}
                                                                onClick={() =>addStudent(student)}
                                                            >
                                                                {loadingEnrollStudent ? (
                                                                    <Loading size={"xs"} />
                                                                ) : (
                                                                    <AiOutlineUserAdd
                                                                        className={"w-5 h-5"}
                                                                    />
                                                                )}

                                                            </button>
                                                        )
                                                    }
                                                } else return (
                                                    <div className="text-sm opacity-50">Enrolled</div>
                                                )
                                            }

                                            return (
                                                <tr key={student.uid}>
                                                    <td>
                                                        <Link
                                                            to={`/students/${student.uid}`}
                                                            className={"link link-neutral"}
                                                            target="_blank"

                                                        >
                                                            <div className="font-bold">{student.profile.instagram}</div>

                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {student.balance.weeks}

                                                    </td>
                                                    <td>
                                                        {student.profile.status}
                                                    </td>
                                                    <th>
                                                        <Action
                                                            isEnrolled={isEnrolled}
                                                            loadingEnrollStudent={loadingEnrollStudent}
                                                            student={student}
                                                        />
                                                    </th>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th>Instagram</th>
                                            <th>Balance</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            ) : (
                                <div className={"flex justify-center items-center h-16 w-full"}>
                                    <span className={"font-bold text-lg"}>No results</span>
                                </div>
                            )
                        )}

                    </div>
                    <div className="modal-action">
                        <button onClick={onCancel} className="btn">Close</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

EnrollStudentInSprint.propTypes = {
    modal: PropTypes.bool.isRequired,
    loadingStudents: PropTypes.bool,
    hideModal: PropTypes.func,
    getStudents: PropTypes.func,
    students: PropTypes.array,
    sprintStudents: PropTypes.array,
    currentSprint: PropTypes.object
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    loadingStudents: state.students.loadingStudents,
    students: state.students.students,
    sprintStudents: state.sprintStudents.students,
    currentSprint: state.sprint.currentSprint,
    loadingEnrollStudent: state.sprintStudents.loadingEnrollStudent
})

const mapDispatchToProps = (dispatch) => ({
    hideModal: (modalType) => dispatch({ type: HIDE_MODAL, payload: modalType }),
    getStudents: (query) => dispatch({ type: GET_STUDENTS, payload: query }),
    resetStudentsData: () => dispatch({ type: RESET_STUDENTS_DATA }),
    enrollStudent: (student) => dispatch({ type: ENROLL_STUDENT, payload: student })
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(EnrollStudentInSprint))
