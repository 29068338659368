import {
    SET_SPRINTS_PAGINATION_PAGE_SIZE, SET_SPRINTS_PAGINATION_TOTAL_RECORDS,
    SET_STUDENTS_PAGINATION_PAGE_SIZE,
    SET_STUDENTS_PAGINATION_TOTAL_RECORDS
} from "../actions/pagination-actions";

const initialState = {
    studentsTotalRecords: 0,
    sprintsTotalRecords: 0,
    sprintsPageSize: 10,
    studentsPageSize: 50
}

export default function paginationReducer(state = initialState, {type, payload}) {

    switch(type) {

        case SET_STUDENTS_PAGINATION_TOTAL_RECORDS: {
            return {
                ...state,
                studentsTotalRecords: payload,
            }
        }

        case SET_STUDENTS_PAGINATION_PAGE_SIZE: {
            return {
                ...state,
                studentsPageSize: payload,
            }
        }

        case SET_SPRINTS_PAGINATION_TOTAL_RECORDS: {
            return {
                ...state,
                sprintsTotalRecords: payload,
            }
        }

        case SET_SPRINTS_PAGINATION_PAGE_SIZE: {
            return {
                ...state,
                sprintsPageSize: payload,
            }
        }

        default:
            return state
    }
}
