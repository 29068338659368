import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects'

import {GET_SERIES,
    GET_SERIES_ERROR,
    GET_SERIES_REQUEST,
    GET_SERIES_SUCCESS
} from "../actions/stories-actions";

import storiesApi from "../api/stories-api";

function* getSeries({ payload }) {

    yield put({ type: GET_SERIES_REQUEST })

    const response = yield call(storiesApi.getSeries, payload)

    if(response.status === 200) {
        yield put({type: GET_SERIES_SUCCESS, payload: response.data })
    } else if(response.status === 404) {
        yield put({type: GET_SERIES_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_SERIES_ERROR })
    }
}

export default function* storiesSaga() {
    yield takeLatest(GET_SERIES, getSeries)
}