import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

const StorySelector = ({ stories, setSelectedStory, selectedStory }) => {

    useEffect(()=>{
        if(stories.length === 0) {
            setSelectedStory('')
        }
    }, [stories])

    return (
        <label className="form-control w-full max-w-xs">
            <div className="label">
                <span className="label-text">Story</span>
            </div>
            <select
                className="select select-bordered select-sm"
                value={selectedStory?.id ? selectedStory.id : ''}
                onChange={(event) => {
                    setSelectedStory(stories.find(story => story.id === event.target.value))
                }}
            >
                <option disabled value={''}>Choose a story</option>
                {stories.map(story => (
                    <option value={story.id}>{story.title}</option>
                ))}
            </select>
        </label>

    );
};

StorySelector.propTypes = {
    stories: PropTypes.array,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StorySelector)