import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {withUseFormHook} from "../helpers/with-form-hook";
import {CLEAN_LOGIN_DATA} from "../redux/actions/auth-actions";
import {useAuth} from "../context/AuthContext";
import ToastMessage from "./elements/toast";
import { toast } from "react-toastify";
import validator from "validator";
import logo from "../assets/1380x330_long@4x.png";
import {Navigate, useLocation} from "react-router-dom";
import Loading from "./Loading";

const Login = ({ form, userInfo, cleanLoginData }) => {

    const location = useLocation();
    const from = location.state?.from
    const pathname = location.pathname || '/'

    const [loading, setLoading] = useState(false)
    let [navigateTo, setNavigateTo] = useState()

    const {
        currentUser,
        doSigninWithEmailPass,
    } = useAuth();

    const localSignIn = async (data) => {
        setLoading(true)
        try {
            toast.dismiss()
            await doSigninWithEmailPass(data.email, data.password)
        } catch (error) {
            setLoading(false)
            switch (error.code) {

                case 'auth/too-many-requests':
                    return toast.error(<ToastMessage text={"Too many requests."} withTryAgain={true} />,
                        {autoClose: false})
                case 'auth/wrong-password':
                    return toast.error(<ToastMessage text={"Wrong password."} withTryAgain={true} />,
                        {autoClose: false})
                case 'auth/user-not-found':
                    return toast.error(<ToastMessage text={"User not found."} withTryAgain={true} />,
                        {autoClose: false})
                default:
                    return toast.error(
                        <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                        {autoClose: false})
            }
        }
    }

    const { register, handleSubmit, formState: { errors } } = form;

    useEffect(() => {
        if(from === '/' && pathname === '/login' || !pathname) {
            setNavigateTo('/')
        } else {
            setNavigateTo(from)
        }

        return () => {
            cleanLoginData()
        }
    }, [])

    if(currentUser && userInfo) {
        return <Navigate to={navigateTo} />;
    } else if(currentUser && !userInfo){
        return <Loading size="lg" />
    } else {
        return (
            <React.Fragment>
                <div className="w-full h-screen flex flex-col items-center justify-center">
                    <img alt="Logo" src={logo} className="w-96 mb-10" />
                    <div className="card shadow-2xl bg-base-100 w-96">
                        <div className="card-body">
                            <form className="w-full space-y-3 mt-4">
                                <div className="form-control">
                                    <input
                                        type="text"
                                        placeholder="Enter your email"
                                        className={`input input-bordered input-md ${errors.email && 'input-error'}`}
                                        {...register("email", {
                                            required: "The field should not be empty",
                                            validate: (email) => {
                                                return validator.isEmail(email) || "Enter email with correct format"
                                            }
                                        })}
                                    />
                                    {errors.email && (
                                        <label className="label">
                                            <span className="label-text-alt">{errors.email.message}</span>
                                        </label>
                                    )}
                                </div>
                                <div className="form-control">
                                    <input
                                        type="password"
                                        placeholder="Enter your password"
                                        className={`input input-bordered input-md ${errors.password && 'input-error'}`}
                                        {...register("password", {
                                            required: "The field should not be empty"
                                        })}
                                    />
                                    {errors.password && (
                                        <label className="label">
                                            <span className="label-text-alt">{errors.password.message}</span>
                                        </label>
                                    )}
                                </div>
                                <button
                                    className={`py-2 px-4 bg-brandGray btn btn-md rounded-full w-full shadow-brand-button text-white hover:text-black`}
                                    onClick={handleSubmit(localSignIn)}
                                >
                                    {loading && <Loading size="xs" />}
                                    <span className="font-medium">LOGIN</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

Login.propTypes = {
    cleanLoginData: PropTypes.func,
    loadingLogin: PropTypes.bool,
    loginError: PropTypes.object
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    loadingLogin: state.auth.loadingLogin,
    loginError: state.auth.loginError
})

const mapDispatchToProps = (dispatch) => ({
    cleanLoginData: () => dispatch({ type: CLEAN_LOGIN_DATA }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(Login))
