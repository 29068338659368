import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const Loading = ({ size }) => {
    return <span className={`loading loading-spinner loading-${size}`}></span>;
};

Loading.propTypes = {
    size: PropTypes.string,
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Loading)
