import {accountApiUrl, homeworkApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const accountClient = client(`${accountApiUrl}/api/v1`)
const homeworkClient = client(`${homeworkApiUrl}/api`)

const getStudentsForEnroll = async (params) => {

    const queryString = new URLSearchParams(params).toString();

    try {
        const response = await accountClient.get(`/users/enrollment_list/?${queryString}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getAccountsByIds = async (ids) => {

    try {
        const data = snakecaseKeys(ids)
        const response = await accountClient.post(`/users/sprint/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getStudentsBySprints = async (params) => {

    const queryString = new URLSearchParams(params).toString();

    try {
        const response = await homeworkClient.get(`/sprint-students/?${queryString}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const enrollStudents = async (sprintId) => {

    try {
        let data = snakecaseKeys(sprintId)
        const response = await homeworkClient.post(`/sprint-students/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const enrollStudent = async (student) => {

    try {
        let data = snakecaseKeys(student)
        const response = await homeworkClient.post(`/sprint-students/add_student/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const unEnrollStudent = async (params) => {

    console.log(params)

    try {
        let data = snakecaseKeys(params)
        const response = await homeworkClient.post(`/sprint-students/remove_student/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const sprintStudentsApi = {
    getStudentsForEnroll,
    getAccountsByIds,
    getStudentsBySprints,
    enrollStudents,
    enrollStudent,
    unEnrollStudent
}

export default sprintStudentsApi
