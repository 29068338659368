import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import VoiceRecorder from "./recorder"
import {withRouter} from "../../helpers/with-router";
import {EditText} from "react-edit-text";

const QuizletPreview = ({ config }) => {

    const params = new URLSearchParams(config.quizletLink)
    const quizletUserId = params.get('i')

    return (
        <>
            <div className={"pt-10 pb-14 text-justify px-4 overflow-hidden h-full"}>
                <p className={"pb-2"}>Is the task not working? Open the <a
                    className="text-blue-600 after:content-['_↗']"
                    href={config.quizletLink}
                    target="_blank"
                >link</a> in your browser</p>
                {config.quizletType && config.quizletId && (
                    <iframe
                        className={"h-full"}
                        src={`https://quizlet.com/${config.quizletId}/${config.quizletType}/embed?i=${quizletUserId}`}
                        // height="100%"
                        width="100%"
                        style={{border:0}}
                    ></iframe>
                )}
            </div>
        </>
    )

}
// 544f73&x=1jj1

QuizletPreview.propTypes = {
    config: PropTypes.object,
}

const mapStateToProps = (state) => ({
    config: state.config.config,
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(QuizletPreview)
