import React, {useEffect} from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";

import {
    SET_HOMEWORK_CONFIG,
    SET_HOMEWORK_CONFIG_MANY,
} from "../../redux/actions/configurator-actions";

import {connect} from "react-redux";
import ImageUpload from "./ImageUpload";
import {ConnectedForm} from "../../helpers/with-form-hook";

const Media = ({ loading, config, setHomeworkConfig, setHomeworkConfigMany }) => {

    useEffect(() => {
        setMediaType('video')
    }, [])

    const setMediaType = (type) => {

        switch (type) {
            case 'video':
                setHomeworkConfigMany([
                    {'isVideo': true},
                    {'isImage': false}
                ])
                break;
            case 'image':
                setHomeworkConfigMany([
                    {'isVideo': false},
                    {'isImage': true}
                ])
                break;
            default:
                setHomeworkConfigMany([
                    {'isVideo': true},
                    {'isImage': false}
                ])
                break;
        }
    }

    return (
        <React.Fragment>
            <div className="mb-10 h-50">
                <div role="tablist" className="tabs tabs-boxed bg-white pl-0">
                    <button
                        role="tab"
                        className={`tab ${config.isVideo ? 'tab-active' : ''}`}
                        onClick={(event) => setMediaType('video')}

                    >
                        Video
                    </button>
                    <button
                        role="tab"
                        onClick={(event) => setMediaType('image')}
                        className={`tab ${config.isImage ? 'tab-active' : ''}`}
                    >
                        Image
                    </button>
                </div>
                {/* Video */}
                <div className={`${config.isVideo ? '' : 'hidden'} mt-5`}>
                    <ConnectedForm>
                        {({register, formState: {errors}}) =>
                            <>
                                <div className={"flex flex-row w-full mb-3"}>
                                    <div className="form-control w-full">
                                        <input
                                            className="whitespace-pre-wrap input input-bordered"
                                            type="text"
                                            name="videoLink"
                                            defaultValue={config.videoLink}
                                            placeholder="Link to video"
                                            {...register("videoLink", {
                                                required: config.withMedia && config.isVideo,
                                                onChange: (event) => {
                                                    setHomeworkConfig({'videoLink': event.target.value})
                                                }
                                            })}
                                        />
                                        <label className="label">
                                            {errors.videoLink &&
                                                <span className="label-text-alt">Data is incorrect</span>}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-control mb-2 flex-row items-center">
                                    <h3 className="mr-2 w-1/3">Start Time</h3>
                                    <input
                                        className="mr-3 block w-1/3 input input-bordered"
                                        type="number"
                                        step="1"
                                        name="startTimeMin"
                                        defaultValue={config.startTimeMin.toString()}
                                        placeholder="Min"
                                        {...register("startTimeMin", {
                                            min: 0,
                                            onChange: (event) => {
                                                let value = !isNaN(event.target.value) ? parseInt(event.target.value) : 0
                                                setHomeworkConfig({'startTimeMin': value})
                                                setHomeworkConfig({'startTime': value * 60 + config.startTimeSec})
                                            }
                                        })}
                                    />
                                    <input
                                        className="w-1/3 input input-bordered"
                                        type="number"
                                        step="1"
                                        name="startTimeSec"
                                        defaultValue={config.startTimeSec.toString()}
                                        placeholder="Sec"
                                        {...register("startTimeSec", {
                                            min: 0,
                                            max: 59,
                                            onChange: (event) => {
                                                let value = !isNaN(event.target.value) ? parseInt(event.target.value) : 0
                                                setHomeworkConfig({'startTimeSec': value})
                                                setHomeworkConfig({'startTime': config.startTimeMin * 60 + value})
                                            }
                                        })}
                                    />
                                </div>
                                <div className="form-control mb-2 flex-row items-center">
                                    <h3 className="mr-2 w-1/3">End Time</h3>
                                    <input
                                        className="mr-3 block w-1/3 input input-bordered"
                                        type="number"
                                        step="1"
                                        name="endTimeMin"
                                        defaultValue={config.endTimeMin.toString()}
                                        placeholder="Min"
                                        {...register("endTimeMin", {
                                            min: 0,
                                            onChange: (event) => {
                                                let value = !isNaN(event.target.value) ? parseInt(event.target.value) : 0
                                                setHomeworkConfig({'endTimeMin': value})
                                                setHomeworkConfig({'endTime': value * 60 + config.endTimeSec})
                                            }
                                        })}
                                    />
                                    <input
                                        className="w-1/3 input input-bordered"
                                        type="number"
                                        step="1"
                                        name="endTimeSec"
                                        defaultValue={config.endTimeSec.toString()}
                                        placeholder="Sec"
                                        {...register("endTimeSec", {
                                            min: 0,
                                            max: 59,
                                            onChange: (event) => {
                                                let value = !isNaN(event.target.value) ? parseInt(event.target.value) : 0
                                                setHomeworkConfig({'endTimeSec': value})
                                                setHomeworkConfig({'endTime': config.endTimeMin * 60 + value})
                                            }
                                        })}
                                    />
                                </div>
                            </>
                        }
                    </ConnectedForm>
                </div>

                {/* Image */}
                <div className={`${config.isImage ? '' : 'hidden'} mt-5`}>
                    <ImageUpload/>
                </div>
            </div>
        </React.Fragment>
    );
}

Media.propTypes = {
    loading: PropTypes.bool,
    config: PropTypes.object,
    setHomeworkConfig: PropTypes.func,
    setHomeworkConfigMany: PropTypes.func
}

const mapStateToProps = (state) => ({
    config: state.config.config,
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfig: (params) => dispatch({type: SET_HOMEWORK_CONFIG, payload: params}),
    setHomeworkConfigMany: (params) => dispatch({type: SET_HOMEWORK_CONFIG_MANY, payload: params})
})

// To make those two function works register it using connect
export default connect(mapStateToProps, mapDispatchToProps)(Media)
