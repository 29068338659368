export const GET_CHALLENGES = "GET_CHALLENGES"
export const GET_CHALLENGES_REQUEST = "GET_CHALLENGES_REQUEST"
export const GET_CHALLENGES_SUCCESS = "GET_CHALLENGES_SUCCESS"
export const GET_CHALLENGES_ERROR = "GET_CHALLENGES_ERROR"

export const SEND_ACTION_CODE = "SEND_ACTION_CODE"
export const SEND_ACTION_CODE_REQUEST = "SEND_ACTION_CODE_REQUEST"
export const SEND_ACTION_CODE_SUCCESS = "SEND_ACTION_CODE_SUCCESS"
export const SEND_ACTION_CODE_ERROR = "SEND_ACTION_CODE_ERROR"

export const GET_SEND_ACTION_PROGRESS = "GET_SEND_ACTION_PROGRESS"
export const GET_SEND_ACTION_PROGRESS_REQUEST = "GET_SEND_ACTION_PROGRESS_REQUEST"
export const STOP_PROGRESS = "STOP_PROGRESS"
