import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import FillPreview from "./FillPreview";
import VoicePreview from "./VoicePreview";

import {getBackground} from "../../helpers/getBackground";
import {FiCheck} from "react-icons/fi";
import {RiRecordCircleLine} from "react-icons/ri";
import {SET_HOMEWORK_CONFIG_ASSIGNMENT} from "../../redux/actions/configurator-actions";
import QuizletPreview from "./QuizletPreview";

const Preview = ({ config, assignment, setHomeworkConfigAssignment }) => {

    const appStyle = config.background ? getBackground(config.background, 'preview') : {}
    const iconStyle = 'w-6 h-6 stroke-current mr-1'

    const componentCleanup = () => {
    }

    const handleChange = (value, rowIndex, elementIndex) => {
        let results = [...assignment]
        results[rowIndex][elementIndex].studentAnswer = value.toLowerCase().trim()
        results[rowIndex][elementIndex].result = value
        setHomeworkConfigAssignment(results)
    }

    useEffect(()=>{
        if (assignment === null || assignment === undefined) {
            setHomeworkConfigAssignment([])
        }
        window.addEventListener('beforeunload', componentCleanup);
    }, [])

    return (
        <div className="artboard phone-1 artboard-demo">
            <div style={appStyle} className="h-full w-full overflow-y-auto">

                {{
                    'voice': <VoicePreview handleChange={handleChange} />,
                    'fill': <FillPreview handleChange={handleChange} />,
                    'quizlet': <QuizletPreview />
                }[config.type]}

                {config.type !== 'quizlet' && (
                    <div className={"flex flex-col items-center mb-8 mt-5"}>
                        <button
                            className="py-2 px-4 bg-brandGray btn btn-md rounded-full btn-wide shadow-brand-button"
                        >
                            {{
                                'voice': <RiRecordCircleLine className={iconStyle}/>,
                                'fill': <FiCheck className={iconStyle}/>
                            }[config.type]}

                            <span className="font-medium">
                            Submit your answer
                        </span>
                        </button>
                        {config.type === 'fill' && <p className="text-xs opacity-60 mt-3">You have only one try</p>}
                    </div>
                )}

            </div>
        </div>
    )

}

Preview.propTypes = {
    config: PropTypes.object,
    assignment: PropTypes.array,
    setHomeworkConfigAssignment: PropTypes.func
}

const mapStateToProps = (state) => ({
    config: state.config.config,
    assignment: state.config.assignment,
})

const mapDispatchToProps = (dispatch) => ({
    setHomeworkConfigAssignment: (assignment) => dispatch({ type: SET_HOMEWORK_CONFIG_ASSIGNMENT, payload: assignment }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
