export const GET_SERIES = "GET_SERIES"
export const GET_SERIES_REQUEST = "GET_SERIES_REQUEST"
export const GET_SERIES_SUCCESS = "GET_SERIES_SUCCESS"
export const GET_SERIES_ERROR = "GET_SERIES_ERROR"

export const CREATE_SERIES = "CREATE_SERIES"
export const CREATE_SERIES_REQUEST = "CREATE_SERIES_REQUEST"
export const CREATE_SERIES_SUCCESS = "CREATE_SERIES_SUCCESS"
export const CREATE_SERIES_ERROR = "CREATE_SERIES_ERROR"

export const GET_STORIES = "GET_SERIES"
export const GET_STORIES_REQUEST = "GET_STORIES_REQUEST"
export const GET_STORIES_SUCCESS = "GET_STORIES_SUCCESS"
export const GET_STORIES_ERROR = "GET_STORIES_ERROR"

export const CREATE_STORY = "CREATE_STORY"
export const CREATE_STORY_REQUEST = "CREATE_STORY_REQUEST"
export const CREATE_STORY_SUCCESS = "CREATE_STORY_SUCCESS"
export const CREATE_STORY_ERROR = "CREATE_STORY_ERROR"

export const UPDATE_STORY = "UPDATE_STORY"
export const UPDATE_STORY_REQUEST = "UPDATE_STORY_REQUEST"
export const UPDATE_STORY_SUCCESS = "UPDATE_STORY_SUCCESS"
export const UPDATE_STORY_ERROR = "UPDATE_STORY_ERROR"