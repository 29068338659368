import React from "react";

import PropTypes from "prop-types";
import {connect} from "react-redux";

import CreateHomeworkModal from "./CreateHomeworkModal";
import CreateSprintModal from "./CreateSprintModal";
import EditFtfPairModal from "./EditFtfPairModal";
import CreateFtfTaskModal from "./CreateFtfTaskModal";
import EnrollStudentToSprint from "./EnrollStudentInSprint";

class ModalContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        if (!this.props.modalType) {
            return null
        }

        const MODAL_TYPES = {
            'createSprint': CreateSprintModal,
            'createHomework': CreateHomeworkModal,
            'createFtf': CreateFtfTaskModal,
            'editFtfPair': EditFtfPairModal,
            'enrollStudentInSprint': EnrollStudentToSprint
        }

        const SpecifiedModal = MODAL_TYPES[this.props.modalType]
        return (
            <div>
                <SpecifiedModal sprintId={this.props.id}/>
            </div>
        )
    }
}

ModalContainer.propTypes = {
    id: PropTypes.string,
    modal: PropTypes.bool,
    modalType: PropTypes.string,
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    modalType: state.common.modalType
})


const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
