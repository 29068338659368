import React from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import ReactPlayer from 'react-player/lazy'
import {
    FiPause,
    FiPlay,
} from "react-icons/fi";
import {
    MdReplay
} from "react-icons/md";

class Player extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAnswers: false,
            readOnly: false,
            previewData: [],
            appStyle: {},
            playing: false
        };

    }

    componentCleanup = () => {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.player && prevProps.config.startTime !== this.props.config.startTime){
            this.player.seekTo(this.props.config.startTime, "seconds")
        }
    }


    initVideo = () => {
        this.player.seekTo(this.props.config.startTime, "seconds")
    }

    handleProgress = state => {
        if (this.props.config.endTime === parseInt(state.playedSeconds)){
            this.setState({playing: false})
        }
    }

    handleStartTime = () => {
        this.player.seekTo(this.props.config.startTime, "seconds")
        this.setState({playing: true})
    }

    handlePlay = () => {
        this.setState({playing: !this.state.playing})
    }

    handleEndTime = () => {
        this.player.seekTo(this.props.config.endTime, "seconds")
    }

    ref = player => {
        this.player = player
    }

    render() {
        let canPlay = ReactPlayer.canPlay(this.props.config.videoLink)

        console.log(canPlay)

        return (
            <React.Fragment>
                <>
                    { canPlay ? (
                        <>
                            <div className="w-full aspect-video">
                                <div className="player-wrapper mb-5">
                                    <ReactPlayer
                                        ref={this.ref}
                                        width="100%"
                                        height="100%"
                                        className={"react-player shadow-lg"}
                                        onReady={this.initVideo}
                                        onProgress={this.handleProgress}
                                        playing={this.state.playing}
                                        url={this.props.config.videoLink}
                                        onError={e => console.log('onError', e)}
                                        config={{
                                            youtube: {
                                                playerVars: {
                                                    showinfo: 0,
                                                    cc_load_policy: 0
                                                }
                                            },
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    { this.state.playing ? (
                                        <button
                                            onClick={this.handlePlay}
                                            className="btn btn-sm rounded-full shadow-brand bg-white border-white border-2 focus:border-white hover:border-white focus:bg-white hover:bg-white">
                                            <FiPause  className="w-6 h-6 stroke-current text-brandGray" />
                                        </button>
                                    ) : (
                                        <button onClick={this.handlePlay} className="btn btn-sm shadow-brand bg-white rounded-full border-white border-2 focus:border-white hover:border-white focus:bg-white hover:bg-white">
                                            <FiPlay className="w-6 h-6 stroke-current text-brandGray"/>
                                        </button>
                                    ) }
                                    <button onClick={this.handleStartTime} className="btn btn-sm shadow-brand bg-white rounded-full border-white border-2 focus:border-white hover:border-white focus:bg-white hover:bg-white">
                                        <MdReplay className="w-6 h-6 stroke-current mr-1 text-brandGray"/>
                                        <span className="text-brandGray">Play again</span>
                                    </button>

                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="w-full aspect-video self-center">
                            <svg width="100%"
                                 height="100%"
                                 style={{ borderRadius: '0.75rem', overflow: 'hidden' }}
                                 className="react-player shadow-lg"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 1920 1080"
                            >
                                <rect fill={"#cbd5e0"} className="cls-1" width="1920" height="1080"/>
                            </svg>
                        </div>
                    ) }
                </>
            </React.Fragment>
        );
    }
}

Player.propTypes = {
    loading: PropTypes.bool,
    config: PropTypes.object
}

const mapStateToProps = (state) => ({
    loading: state.config.loading,
    config: state.config.config
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Player)
