import {
    put,
    call,
    takeLatest,
    delay,
    throttle
} from 'redux-saga/effects'

import {
    GET_CHALLENGES,
    GET_CHALLENGES_ERROR,
    GET_CHALLENGES_REQUEST,
    GET_CHALLENGES_SUCCESS,
    GET_SEND_ACTION_PROGRESS,
    GET_SEND_ACTION_PROGRESS_REQUEST,
    SEND_ACTION_CODE,
    SEND_ACTION_CODE_ERROR,
    SEND_ACTION_CODE_REQUEST,
    SEND_ACTION_CODE_SUCCESS, STOP_PROGRESS,
} from "../actions/challenge-actions";

import challengeApi from "../api/challenge-api";

function* getChallenges() {

    yield put({ type: GET_CHALLENGES_REQUEST })

    const response = yield call(challengeApi.getChallenges)

    if(response.status === 200) {
        yield put({type: GET_CHALLENGES_SUCCESS, payload: response.data.results })
    } else if(response.status === 404) {
        yield put({type: GET_CHALLENGES_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_CHALLENGES_ERROR })
    }
}

function* sendActionCode({ payload }) {

    yield put({ type: SEND_ACTION_CODE_REQUEST })

    const response = yield call(challengeApi.sendActionCode, payload.data)

    if (response.status === 200) {
        if(payload.withProgress === true) {

            const data = {
                taskId: response.data.data.taskId,
            }
            yield call(getSendActionProgress, {payload: data})
        }
        yield put({type: SEND_ACTION_CODE_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: SEND_ACTION_CODE_ERROR })
    }
}

function* getSendActionProgress({ payload }) {

    const response = yield call(challengeApi.getProgress, payload.taskId)

    if(response) {
        yield put({ type: GET_SEND_ACTION_PROGRESS_REQUEST, payload: { taskId: payload.taskId, ...response.data } })
        let complete = response.data.complete

        if (complete === false) {
            yield call(getSendActionProgress, {payload: payload})
        }
        else {
            yield put({ type: STOP_PROGRESS});
        }
    }
}

export default function* challengeSaga() {
    yield takeLatest(GET_CHALLENGES, getChallenges)
    yield takeLatest(SEND_ACTION_CODE, sendActionCode)
    yield throttle(20, GET_SEND_ACTION_PROGRESS, getSendActionProgress)
}
