import {accountApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import client from "./client";

const accountClient = client(`${accountApiUrl}/api/v1/stories`)

const getSeries = async (params) => {

    const queryString = new URLSearchParams(params).toString();

    try {
        const response = await accountClient.get(`/series/?${queryString}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const storiesApi = {
    getSeries,
}

export default storiesApi
