import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {AiFillDelete, AiOutlineDelete} from "react-icons/ai";
import {UNENROLL_STUDENT} from "../../../../redux/actions/sprint-students-actions";
import Loading from "../../../Loading";

const UnEnrollStudentButton = ({ student, sprint, unEnrollStudent, loadingUnEnrollStudents }) => {

    const [studentId, setStudentId] = useState(null)

    const unEnrollStudentHandle = (params) => {
        unEnrollStudent(params)
        setStudentId(params.studentId)
    }

    useEffect(()=>{
        return ()=>{
            setStudentId(null)
        }
    }, [])

    return (
        <React.Fragment>
            <button
                disabled={(sprint.writeOff || !sprint.enrolled) && 'disabled'}
                className={"btn btn-sm btn-circle btn-ghost"}
                onClick={() => unEnrollStudentHandle({studentId: student.uid, sprintId: sprint.id})}
            >
                {loadingUnEnrollStudents && (studentId === student.uid) ? (
                    <Loading size={"sm"} />
                ) : (
                    <AiOutlineDelete className={`h-5 w-5`}/>
                )}
            </button>
        </React.Fragment>
    )
}


UnEnrollStudentButton.propTypes = {
    student: PropTypes.object.isRequired,
    sprint: PropTypes.object.isRequired,
    unEnrollStudent: PropTypes.func,
    loadingUnEnrollStudents: PropTypes.bool
}

const mapStateToProps = (state) => ({
    loadingUnEnrollStudents: state.sprintStudents.loadingUnEnrollStudents
})

const mapDispatchToProps = (dispatch) => ({
    unEnrollStudent: (params) => dispatch({type: UNENROLL_STUDENT, payload: params})
})

export default connect(mapStateToProps, mapDispatchToProps)(UnEnrollStudentButton)
