export const assignmentConvertor = (content, assignmentType) => {
    return content.blocks.map((block) => {
        const intoPairs = (xs) =>
            xs.slice(1).map((x, i) => [xs[i], x]);
        const breakAt = (places, str) =>
            intoPairs([0, ...places, str.length]).map(([a, b]) =>
                str.substring(a, b)
            );

        const breakWhere = (words, str) => {
            return breakAt(
                words.reduce(
                    (a, { offset, length, style }) => [...a, offset, offset + length],
                    []
                ),
                str
            );
        };

        const createNodes = (links, str) => {
            const sortedLinks = links.slice(0).sort(({ offset: o1 }, { offset: o2 }) => o1 - o2);

            if (assignmentType === "fill" || assignmentType === "voice") {
                let currentLinkIndex = 0; // Индекс текущего объекта link

                return breakWhere(sortedLinks, str).map((s, i) => {
                    if (i % 2 === 0) {
                        return { text: s, isAnswer: false };
                    } else {
                        // Находим соответствующий объект link или устанавливаем его как пустой объект, если он отсутствует
                        const link = sortedLinks[currentLinkIndex] || { style: "" };
                        currentLinkIndex++;

                        return {
                            text: s,
                            isAnswer: true,
                            color: "teal",
                            result: "",
                            studentAnswer: "",
                            style: link.style,
                        };
                    }
                }).filter(({ text }) => text.length > 0);
            }
        };

        const str = block.text;
        const links = block.inlineStyleRanges;

        return createNodes(links, str);
    });
};
