import {
    put,
    call,
    takeLatest,
    takeEvery
} from 'redux-saga/effects'

import {
    SET_LOADING,
    GET_HOMEWORKS_BY_SPRINT,
    GET_HOMEWORK_BY_ID_REQUESTED,
    SET_CURRENT_HOMEWORK,
    CREATE_HOMEWORK,
    DELETE_HOMEWORK,
    DELETE_HOMEWORK_REQUESTED,
    GET_HOMEWORKS_BY_SPRINT_REQUEST,
    GET_HOMEWORKS_BY_SPRINT_SUCCESS,
    GET_HOMEWORKS_BY_SPRINT_ERROR,
    CREATE_HOMEWORK_REQUEST,
    CREATE_HOMEWORK_SUCCESS,
    CREATE_HOMEWORK_ERROR,
    UPDATE_HOMEWORK_REQUEST,
    UPDATE_HOMEWORK_SUCCESS,
    UPDATE_HOMEWORK_ERROR,
    UPDATE_HOMEWORK,
    GET_ANSWERS_BY_HOMEWORK,
    GET_ANSWERS_BY_HOMEWORK_REQUEST,
    GET_ANSWERS_BY_HOMEWORK_SUCCESS,
    GET_ANSWERS_BY_HOMEWORK_ERROR,
} from '../actions/homework-actions'

import {
    getAllHomeworksBySprint,
    createNewHomework,
    updateExistHomework,
    deleteExistedHomework,
    getHomeworkById,
    getAnswers
} from '../api/homework-api'

function* getHomeworksBySprint({ payload }) {

    yield put({ type: GET_HOMEWORKS_BY_SPRINT_REQUEST })

    const response = yield call(getAllHomeworksBySprint, payload)

    if(response.status === 200) {
        yield put({type: GET_HOMEWORKS_BY_SPRINT_SUCCESS, payload: response.data })
    } else if (response.status === 404){
        yield put({type: GET_HOMEWORKS_BY_SPRINT_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_HOMEWORKS_BY_SPRINT_ERROR })
    }
}

function* getCurrentHomework({ payload }) {
    yield put({ type: SET_LOADING, payload: true })
    const response = yield call(getHomeworkById, payload)

    if (response.status === 200) {
        yield put({type: SET_CURRENT_HOMEWORK, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: SET_CURRENT_HOMEWORK, payload: {} })
    }

}

function* createHomework({ payload }) {

    yield put({ type: CREATE_HOMEWORK_REQUEST })

    const response = yield call(createNewHomework, payload)

    if(response.status === 200) {
        yield put({type: CREATE_HOMEWORK_SUCCESS, payload: response.data })
    } else if (response.status === 404){
        yield put({type: CREATE_HOMEWORK_SUCCESS, payload: [] })
    } else {
        yield put({ type: CREATE_HOMEWORK_ERROR })
    }
}

function* updateHomework({ payload }) {

    yield put({ type: UPDATE_HOMEWORK_REQUEST })

    const response = yield call(updateExistHomework, payload)

    if(response.status === 200) {
        yield put({type: UPDATE_HOMEWORK_SUCCESS })
    } else {
        yield put({ type: UPDATE_HOMEWORK_ERROR })
    }
}

function* deleteHomework({ payload }) {
    yield put({ type: SET_LOADING, payload: true })

    const homework = yield call(deleteExistedHomework, payload)

    yield put({ type: DELETE_HOMEWORK, payload: homework })
}

function* getAnswersByHomework({ payload }) {

    yield put({ type: GET_ANSWERS_BY_HOMEWORK_REQUEST })
    const response = yield call(getAnswers, payload)

    if (response.status === 200) {
        yield put({type: GET_ANSWERS_BY_HOMEWORK_SUCCESS, payload: response.data })
    }
    if(response.status >=400 && response.status < 600){
        yield put({ type: GET_ANSWERS_BY_HOMEWORK_ERROR })
    }
}


export default function* homeworkSaga() {
    yield takeLatest(CREATE_HOMEWORK, createHomework)
    yield takeLatest(UPDATE_HOMEWORK, updateHomework)
    yield takeEvery(GET_HOMEWORKS_BY_SPRINT, getHomeworksBySprint)
    yield takeLatest(GET_HOMEWORK_BY_ID_REQUESTED, getCurrentHomework)
    yield takeLatest(DELETE_HOMEWORK_REQUESTED, deleteHomework)
    yield takeEvery(GET_ANSWERS_BY_HOMEWORK, getAnswersByHomework)
}
