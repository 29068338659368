import {
    SET_CURRENT_HOMEWORK,
    DELETE_HOMEWORK,
    GET_ANSWERS_BY_HOMEWORK,
    GET_HOMEWORKS_BY_SPRINT_REQUEST,
    GET_HOMEWORKS_BY_SPRINT_SUCCESS,
    GET_HOMEWORKS_BY_SPRINT_ERROR,
    CREATE_HOMEWORK_REQUEST,
    CREATE_HOMEWORK_SUCCESS,
    CREATE_HOMEWORK_ERROR,
    UPDATE_HOMEWORK_REQUEST,
    UPDATE_HOMEWORK_SUCCESS,
    UPDATE_HOMEWORK_ERROR,
    GET_ANSWERS_BY_HOMEWORK_SUCCESS,
    GET_ANSWERS_BY_HOMEWORK_ERROR,
    GET_ANSWERS_BY_HOMEWORK_REQUEST,
} from '../actions/homework-actions'
import {toast} from "react-toastify";
import ToastMessage from "../../components/elements/toast";

const initialState = {
    loadingHomeworks: false,
    getHomeworksError: null,
    loadingCreateHomework: null,
    createHomeworkError: null,
    homeworks: null,
    answers: [],
    currentHomework: {},
    loadingAnswers: false
}

export default function homeworkReducer(state = initialState, {type, payload}) {

    switch(type) {

        case GET_HOMEWORKS_BY_SPRINT_REQUEST:
            return {
                ...state,
                loadingHomeworks: true,
                getHomeworksError: null
            }

        case GET_HOMEWORKS_BY_SPRINT_SUCCESS:
            return {
                ...state,
                homeworks: payload,
                loadingHomeworks: false,
                getHomeworksError: null
            }

        case GET_HOMEWORKS_BY_SPRINT_ERROR:
            return {
                ...state,
                homeworks: null,
                loadingHomeworks: false,
                getHomeworksError: 'Can`t get homeworks. Try again later'
            }

        case SET_CURRENT_HOMEWORK:

            return {
                ...state,
                currentHomework: payload,
                title: payload.title
            }

        case CREATE_HOMEWORK_REQUEST:
            return {
                ...state,
                loadingCreateHomework: true,
                createHomeworkError: null
            }

        case CREATE_HOMEWORK_SUCCESS:
            return {
                ...state,
                homeworks: [payload, ...state.homeworks],
                loadingCreateHomework: false,
                createHomeworkError: null
            }

        case CREATE_HOMEWORK_ERROR:
            return {
                ...state,
                homeworks: null,
                loadingCreateHomework: false,
                createHomeworkError: 'Can`t create homework. Try again later'
            }

        case UPDATE_HOMEWORK_REQUEST:
            return {
                ...state,
                loadingUpdateHomework: true,
                updateHomeworkError: null
            }

        case UPDATE_HOMEWORK_SUCCESS:
            return {
                ...state,
                loadingUpdateHomework: false,
                updateHomeworkError: null
            }

        case UPDATE_HOMEWORK_ERROR:
            return {
                ...state,
                homeworks: null,
                loadingUpdateHomework: false,
                updateHomeworkError: 'Can`t update homework. Try again later'
            }

        case DELETE_HOMEWORK:
            return {
                ...state,
                homeworks: state.homeworks.filter(sprint => sprint.id !== payload),
                loading: false
            }

        case GET_ANSWERS_BY_HOMEWORK_REQUEST:
            return {
                ...state,
                loadingAnswers: true
            }

        case GET_ANSWERS_BY_HOMEWORK_SUCCESS:
            return {
                ...state,
                answers: payload,
                loadingAnswers: false,
            }

        case GET_ANSWERS_BY_HOMEWORK_ERROR:
            toast.error(<ToastMessage text={payload.text} code={payload.code} />)
            return {
                ...state,
                loadingAnswers: false
            }

        default:
            return state
    }
}
