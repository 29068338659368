import {accountApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import client from "./client";

const accountClient = client(`${accountApiUrl}/api/v1`)

const getChallenges = async () => {
    try {
        const response = await accountClient.get('/challenge/challenge/')
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const sendActionCode = async (params) => {
    try {
        let data = snakecaseKeys(params)
        const response = await accountClient.post(`/challenge/challenge/challenge_action_code/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

export const getProgress = async (taskId) => {

    try {
        const response = await accountClient.get(`/celery-progress/?task_id=${taskId}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const challengeApi = {
    getChallenges,
    sendActionCode,
    getProgress,
}

export default challengeApi
