export const GET_SPRINT_STUDENTS = "GET_SPRINT_STUDENTS"
export const GET_SPRINT_STUDENTS_REQUEST = "GET_SPRINT_STUDENTS_REQUEST"
export const GET_SPRINT_STUDENTS_SUCCESS = "GET_SPRINT_STUDENTS_SUCCESS"
export const GET_SPRINT_STUDENTS_ERROR = "GET_SPRINT_STUDENTS_ERROR"

export const GET_STUDENTS_FOR_ENROLL = "GET_STUDENTS_FOR_ENROLL"

export const ENROLL_STUDENTS = "ENROLL_STUDENTS"
export const ENROLL_STUDENTS_REQUEST = "ENROLL_STUDENTS_REQUEST"
export const ENROLL_STUDENTS_SUCCESS = "ENROLL_STUDENTS_SUCCESS"
export const ENROLL_STUDENTS_ERROR = "ENROLL_STUDENTS_ERROR"

export const ENROLL_STUDENT = "ENROLL_STUDENT"
export const ENROLL_STUDENT_REQUEST = "ENROLL_STUDENT_REQUEST"
export const ENROLL_STUDENT_SUCCESS = "ENROLL_STUDENT_SUCCESS"
export const ENROLL_STUDENT_ERROR = "ENROLL_STUDENT_ERROR"

export const UNENROLL_STUDENT = "UNENROLL_STUDENT"
export const UNENROLL_STUDENT_REQUEST = "UNENROLL_STUDENT_REQUEST"
export const UNENROLL_STUDENT_SUCCESS = "UNENROLL_STUDENT_SUCCESS"
export const UNENROLL_STUDENT_ERROR = "UNENROLL_STUDENT_ERROR"

export const UPDATE_SPRINT_STUDENT = "UPDATE_SPRINT_STUDENT"
export const UPDATE_SPRINT_STUDENT_REQUEST = "UPDATE_SPRINT_STUDENT_REQUEST"
export const UPDATE_SPRINT_STUDENT_SUCCESS = "UPDATE_SPRINT_STUDENT_SUCCESS"
export const UPDATE_SPRINT_STUDENT_ERROR = "UPDATE_SPRINT_STUDENT_ERROR"

export const SET_CURRENT_STUDENT = "SET_CURRENT_STUDENT"

export const SET_SPRINT_STUDENTS = "SET_SPRINT_STUDENTS"
export const SET_SPRINT_STUDENTS_REQUESTED = "SET_SPRINT_STUDENTS_REQUESTED"
