import {
    GET_SPRINT_STUDENTS_REQUEST,
    GET_SPRINT_STUDENTS_SUCCESS,
    GET_SPRINT_STUDENTS_ERROR,
    ENROLL_STUDENTS_REQUEST,
    ENROLL_STUDENTS_SUCCESS,
    ENROLL_STUDENTS_ERROR,
    UNENROLL_STUDENT_ERROR,
    UNENROLL_STUDENT_SUCCESS,
    UNENROLL_STUDENT_REQUEST,
    UPDATE_SPRINT_STUDENT_REQUEST,
    UPDATE_SPRINT_STUDENT_SUCCESS,
    UPDATE_SPRINT_STUDENT_ERROR,
    SET_CURRENT_STUDENT, ENROLL_STUDENT_REQUEST, ENROLL_STUDENT_SUCCESS, ENROLL_STUDENT_ERROR
} from "../actions/sprint-students-actions";
import {toast} from "react-toastify";
import ToastMessage from "../../components/elements/toast";

const initialState = {
    loadingSprintStudents: false,
    loadingEnrollStudents: false,
    loadingEnrollStudent: false,
    loadingUpdateStudent: false,
    loadingUnEnrollStudents: false,
    getSprintStudentsError: null,
    enrollStudentsError: null,
    updateStudentsError: null,
    students: null,
    enrolledStudentsCount: 0,
    currentStudent: null
}

export default function sprintStudentsReducer(state = initialState, {type, payload}) {

    switch(type) {

        case GET_SPRINT_STUDENTS_REQUEST: {
            return {
                ...state,
                loadingSprintStudents: true,
                getSprintStudentsError: null
            }
        }

        case GET_SPRINT_STUDENTS_SUCCESS:
            return {
                ...state,
                students: payload.students,
                loadingSprintStudents: false,
                getSprintStudentsError: null,
                enrolledStudentsCount: payload.count !== undefined && !payload.isFilter ? payload.count : state.enrolledStudentsCount,
            }

        case GET_SPRINT_STUDENTS_ERROR:
            return {
                ...state,
                students: null,
                loadingSprintStudents: false,
                getSprintStudentsError: 'Can`t get sprint students. Try again later'
            }

        case ENROLL_STUDENTS_REQUEST:
            return {
                ...state,
                loadingEnrollStudents: true,
                enrollStudentsError: null,
            }

        case ENROLL_STUDENTS_SUCCESS:
            return {
                ...state,
                loadingEnrollStudents: false,
                enrollStudentsError: null
            }

        case ENROLL_STUDENTS_ERROR:
            return {
                ...state,
                students: null,
                loadingEnrollStudents: false,
                enrollStudentsError: 'Can`t enroll students. Try again later'
            }

        case ENROLL_STUDENT_REQUEST:
            return {
                ...state,
                loadingEnrollStudent: true,
            }

        case ENROLL_STUDENT_SUCCESS:
            return {
                ...state,
                loadingEnrollStudent: false,
            }

        case ENROLL_STUDENT_ERROR:
            toast.error(<ToastMessage text={payload.message} withTryAgain={true} withSupportButton={true} code={payload.code} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingEnrollStudent: false,
            }

        case UNENROLL_STUDENT_REQUEST:
            return {
                ...state,
                loadingUnEnrollStudents: true,
            }

        case UNENROLL_STUDENT_SUCCESS:
            return {
                ...state,
                students: state.students.filter(student => student.id !== payload),
                loadingUnEnrollStudents: false,
            }

        case UNENROLL_STUDENT_ERROR:
            toast.error(<ToastMessage text={payload.message} withTryAgain={true} withSupportButton={true} code={payload.code} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingUnEnrollStudents: false,
            }

        case UPDATE_SPRINT_STUDENT_REQUEST:
            return {
                ...state,
                loadingUpdateStudent: true,
                updateStudentError: null
            }

        case UPDATE_SPRINT_STUDENT_SUCCESS:
            return {
                ...state,
                students: state.students.map((student) => {
                    if(student.id === payload.id) {
                        student = payload
                    }
                    return student
                }),
                loadingUpdateStudent: false,
                updateStudentError: null
            }

        case UPDATE_SPRINT_STUDENT_ERROR:
            return {
                ...state,
                sprints: null,
                loadingUpdateStudent: false,
                updateStudentError: 'Can`t update student info. Try again later'
            }

        case SET_CURRENT_STUDENT:
            return {
                ...state,
                currentStudent: payload,
            }

        default:
            return state
    }
}
