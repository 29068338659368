import {toast} from "react-toastify";
import ToastMessage from "../../components/elements/toast";
import React from "react";
import {
    GET_CHALLENGES_ERROR,
    GET_CHALLENGES_REQUEST,
    GET_CHALLENGES_SUCCESS, GET_SEND_ACTION_PROGRESS_REQUEST, SEND_ACTION_CODE_ERROR,
    SEND_ACTION_CODE_REQUEST, SEND_ACTION_CODE_SUCCESS, STOP_PROGRESS
} from "../actions/challenge-actions";

const initialState = {
    challenges: null,
    loadingChallenges: false,
    sendingActionCode: false,
    calculateActionProgress: null
}

export default function challengeReducer(state = initialState, {type, payload}) {
    switch(type) {
        case GET_CHALLENGES_REQUEST: {
            return {
                ...state,
                loadingChallenges: true,
            }
        }

        case GET_CHALLENGES_SUCCESS:
            return {
                ...state,
                challenges: payload,
                loadingChallenges: false,
            }

        case GET_CHALLENGES_ERROR:
            toast.error(<ToastMessage text={"Can't get challenges"} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                challenges: null,
                loadingChallenges: false,
            }

        case SEND_ACTION_CODE_REQUEST:
            return {
                ...state,
                sendingActionCode: true
            }

        case SEND_ACTION_CODE_SUCCESS:
            // payload.message !== null && toast.info(<ToastMessage text={payload.message} />, {
            //     autoClose: false
            // })
            return {
                ...state,
                sendingActionCode: false
            }

        case SEND_ACTION_CODE_ERROR:
            toast.error(<ToastMessage text={"Can't calculate challenge action"} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                sendingActionCode: false,
            }

        case GET_SEND_ACTION_PROGRESS_REQUEST:
            return {
                ...state,
                calculateActionProgress: payload,
            }

        case STOP_PROGRESS:
            return {
                ...state,
                calculateActionProgress: null
            }

        default:
            return state
    }
}
