import React, {useRef} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {IoMdClose} from "react-icons/io";
import {IconContext} from "react-icons/lib";
import {Parser} from "html-to-react";

const Story = ({
                   content,
                   storyBackground
}) => {

    const htmlParser = new Parser();
    const contentRef = useRef()


    return (
        <div className={`flex flex-col w-full h-full justify-star pt-5 ${storyBackground}`}>
            {/*HEADER*/}
            <div
                className={"flex flex-row w-full justify-end pr-2.5"}
                style={{ zIndex: 1000 }}
            >
                <IconContext.Provider value={{className: "w-6 h-6"}}>
                    <IoMdClose />
                </IconContext.Provider>
            </div>
            {/*BODY*/}
            <div ref={contentRef} className={"h-full w-full pt-2 px-3"}>
                {htmlParser.parse(content)}
            </div>
        </div>
    );
};

Story.propTypes = {
    content: PropTypes.any,
    storyBackground: PropTypes.string,
    contentRef: PropTypes.any
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Story)