import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";

import {mainNav, settingsNav} from "./data/navigation";
import ModalContainer from "./components/elements/modals/ModalContainer";
import Login from "./components/Login";
import SprintsContainer from "./containers/SprintsContainer";
import Profile from "./components/Profile";
import SprintContainer from "./containers/SprintContainer";
import HomeworkContainer from "./containers/HomeworkContainer";
import EditorContainer from "./containers/EditorContainer";
import DashboardContainer from "./containers/DashboardContainer";
import StudentsContainer from "./containers/StudentsContainer";
import StudentContainer from "./containers/StudentContainer";
import Player from "./components/Preview/Player";
import PrivateRoute from "./routers/PrivateRoute";
import {useAuth} from "./context/AuthContext";
import {GET_USER_INFO} from "./redux/actions/user-actions";
import Logout from "./components/Logout";
import ToastMessage from "./components/elements/toast";
import {toast} from "react-toastify";
import {ServiceWorkerUpdateListener} from "./ServiceWorkerUpdateListener";
import Settings from "./components/Settings";
import ChallengesContainer from "./containers/ChallengesContainer";
import StoriesBuilderContainer from "./containers/StoriesBuilderContainer";

const App = ({ getUserInfo, userInfo }) => {

    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [swListener, setSwListener] = useState({});

    const { currentUser } = useAuth()
    const location = useLocation()
    const navigate = useNavigate()
    const pathname = location.pathname
    const [backgroundStyle, setBackgroundStyle] = useState('bg-base-100')

    useEffect(()=>{
        if(pathname === '/login'){
            setBackgroundStyle('brandYellow')
        } else {
            setBackgroundStyle('bg-base-100')
        }
    }, [pathname])

    useEffect(() => {

        if(!userInfo && currentUser){
            currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (!!idTokenResult.claims.admin || !!idTokenResult.claims.staff) {
                        getUserInfo(currentUser.uid);
                    } else {
                        toast.error(
                            <ToastMessage text={`Access denied.`} withSupportButton={true} />,
                            {autoClose: false}
                        )
                        navigate('/logout');
                    }
                })
                .catch((error) => {
                    toast.error(
                        <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                        {autoClose: false}
                    )
                });

        }
    }, [userInfo, currentUser])

    useEffect(() => {
        const updater = async () => {
            if (process.env.NODE_ENV !== "development") {
                const listener = await new ServiceWorkerUpdateListener();
                setSwListener(listener);

                listener.onupdatewaiting = () => {
                    setUpdateWaiting(true);
                };
                listener.onupdateready = () => {
                    window.location.reload();
                };

                navigator.serviceWorker.getRegistration().then((reg) => {
                    listener.addRegistration(reg);
                    setRegistration(reg);
                });

                return () => {
                    listener.removeEventListener();
                };
            }
        };

        updater();
    }, []);

    useEffect(() => {
        if (updateWaiting) {
            toast.info(<UpdateWaiting />, {
                autoClose: false,
                toastId: 'update'
            });
        } else {
            toast.dismiss('update');
        }
    }, [updateWaiting]);

    const UpdateWaiting = () => {
        const handleUpdateButton = (event) => {
            event.preventDefault();
            swListener.skipWaiting(registration.waiting);
        };

        return (
            <div className="flex flex-row justify-between items-center w-full xs:max-w-laptop">
                <div>Update waiting!</div>
                <button className="btn btn-md btn-primary ml-2" onClick={handleUpdateButton}>Update</button>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="drawer lg:drawer-open">
                <input id="main-menu" type="checkbox" className="drawer-toggle" />
                <div className={`drawer-content flex-grow block overflow-x-hidden ${backgroundStyle} text-base-content`}>
                    <div className="lg:hidden sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-90 backdrop-blur transition-all duration-100 bg-base-100 text-base-content shadow-sm">
                        <nav className="navbar w-full">
                            <div className="flex flex-1">
                                <div className="flex-none lg:hidden">
                                    <label htmlFor="main-menu" className="btn btn-square btn-ghost">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                                    </label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <a href="/" className="px-2 flex-0 btn btn-ghost md:px-4 nuxt-link-active" aria-label="Homepage">
                                        <div className="inline-block text-xl font-title text-primary"><span className="lowercase">homework</span>
                                            <span className="uppercase text-lg text-base-content">NINJA</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className={"p-4 lg:p-10 flex flex-col drawer-content min-h-screen"}>
                        <Routes>
                            {['/', '/sprints'].map((path, index) => (
                                <Route path={path} element={<PrivateRoute><SprintsContainer /></PrivateRoute>} key={index} />
                            ))}
                            <Route exact path="/login" element={<Login />} />
                            <Route exact path="/logout" element={<Logout />} />
                            <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                            <Route exact path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                            <Route exact path="/challenges" element={<PrivateRoute><ChallengesContainer /></PrivateRoute>} />
                            <Route exact path="/sprints/:id" element={<PrivateRoute><SprintContainer /></PrivateRoute>} />
                            <Route exact path="/homeworks/:id/view" element={<PrivateRoute><HomeworkContainer /></PrivateRoute>} />
                            <Route exact path="/homeworks/:id/editor" element={<PrivateRoute><EditorContainer /></PrivateRoute>} />
                            <Route exact path="/dashboard" element={<PrivateRoute><DashboardContainer /></PrivateRoute>} />
                            <Route exact path="/students" element={<PrivateRoute><StudentsContainer /></PrivateRoute>} />
                            <Route exact path="/students/:id" element={<PrivateRoute><StudentContainer /></PrivateRoute>} />
                            <Route exact path="/editor" element={<PrivateRoute><EditorContainer /></PrivateRoute>} />
                            <Route exact path="/stories-builder" element={<PrivateRoute><StoriesBuilderContainer /></PrivateRoute>} />
                            <Route exact path="/player" element={<PrivateRoute><Player /></PrivateRoute>} />
                            <Route path="*" element={ <div style={{ padding: "1rem" }}>
                                        <p>There's nothing here!</p>
                                    </div>} />
                        </Routes>
                    </div>
                </div>
                <Sidebar settingsNav={settingsNav} mainNav={mainNav} />
            </div>
            <ModalContainer />
        </React.Fragment>
    )
}

App.propTypes = {
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: (uid) => dispatch({ type: GET_USER_INFO, payload: uid }),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
