import {toast} from "react-toastify";
import ToastMessage from "../../components/elements/toast";
import React from "react";
import {
    GET_SERIES_ERROR,
    GET_SERIES_REQUEST,
    GET_SERIES_SUCCESS,
} from "../actions/stories-actions";

const initialState = {
    series: [],
    loadingSeries: false,
    creatingSeries: false,
    creatingStory: false,
    updatingStory: false
}

export default function storiesReducer(state = initialState, {type, payload}) {
    switch(type) {

        case GET_SERIES_REQUEST: {
            return {
                ...state,
                loadingSeries: true,
            }
        }

        case GET_SERIES_SUCCESS:
            return {
                ...state,
                series: payload.results,
                loadingSeries: false,
            }

        case GET_SERIES_ERROR:
            toast.error(<ToastMessage text={"Can`t get series"} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                series: null,
                loadingSeries: false,
            }

        default:
            return state
    }
}
