export const GET_CONFIG_BY_HOMEWORK = "GET_CONFIG_BY_HOMEWORK"
export const GET_CONFIG_BY_HOMEWORK_REQUEST = "GET_CONFIG_BY_HOMEWORK_REQUEST"
export const GET_CONFIG_BY_HOMEWORK_SUCCESS = "GET_CONFIG_BY_HOMEWORK_SUCCESS"
export const GET_CONFIG_BY_HOMEWORK_ERROR = "GET_CONFIG_BY_HOMEWORK_ERROR"

export const CREATE_HOMEWORK_CONFIG = "CREATE_HOMEWORK_CONFIG"
export const CREATE_HOMEWORK_CONFIG_REQUEST = "CREATE_HOMEWORK_CONFIG_REQUEST"
export const CREATE_HOMEWORK_CONFIG_SUCCESS = "CREATE_HOMEWORK_CONFIG_SUCCESS"
export const CREATE_HOMEWORK_CONFIG_ERROR = "CREATE_HOMEWORK_CONFIG_ERROR"

export const UPDATE_HOMEWORK_CONFIG = "UPDATE_HOMEWORK_CONFIG"
export const UPDATE_HOMEWORK_CONFIG_REQUEST = "UPDATE_HOMEWORK_CONFIG_REQUEST"
export const UPDATE_HOMEWORK_CONFIG_SUCCESS = "UPDATE_HOMEWORK_CONFIG_SUCCESS"
export const UPDATE_HOMEWORK_CONFIG_ERROR = "UPDATE_HOMEWORK_CONFIG_ERROR"

export const SET_HOMEWORK_CONFIG = "SET_HOMEWORK_CONFIG"
export const SET_HOMEWORK_CONFIG_MANY = "SET_HOMEWORK_CONFIG_MANY"

export const SET_EMPTY_HOMEWORK_CONFIG = "SET_EMPTY_HOMEWORK_CONFIG"

export const SET_HOMEWORK_CONFIG_ASSIGNMENT = "SET_HOMEWORK_CONFIG_ASSIGNMENT"
export const SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED = "SET_HOMEWORK_CONFIG_ASSIGNMENT_REQUESTED"

export const SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA = "SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA"
export const SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA_REQUESTED = "SET_HOMEWORK_CONFIG_EDITOR_RAW_DATA_REQUESTED"

export const CREATING_CONFIG_PROGRESS = "CREATING_CONFIG_PROGRESS"