import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from './redux/store'
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react'
import AuthProvider from "./context/AuthContext";
import ToastProvider from "./context/ToastContext";
import {BrowserRouter as Router} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './components/elements/toast/toast.css'

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<React.Fragment>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <AuthProvider>
                    <ToastProvider>
                        <App />
                    </ToastProvider>
                </AuthProvider>
            </Router>
        </PersistGate>
    </Provider>
</React.Fragment>);

serviceWorkerRegistration.register();

reportWebVitals();
