import {
    put,
    call,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects'

import {
    GET_SPRINT_STUDENTS_REQUEST,
    GET_SPRINT_STUDENTS_SUCCESS,
    GET_SPRINT_STUDENTS_ERROR,
    GET_SPRINT_STUDENTS,
    ENROLL_STUDENTS_ERROR,
    ENROLL_STUDENTS,
    ENROLL_STUDENTS_SUCCESS,
    ENROLL_STUDENTS_REQUEST,
    GET_STUDENTS_FOR_ENROLL,
    ENROLL_STUDENT_REQUEST,
    ENROLL_STUDENT_SUCCESS,
    ENROLL_STUDENT_ERROR,
    ENROLL_STUDENT,
    UNENROLL_STUDENT, UNENROLL_STUDENT_REQUEST, UNENROLL_STUDENT_SUCCESS, UNENROLL_STUDENT_ERROR
} from "../actions/sprint-students-actions";

import sprintStudentsApi from "../api/sprint-students-api";
import {UPDATE_SPRINT} from "../actions/sprint-actions";
import {SET_STUDENTS_PAGINATION_TOTAL_RECORDS} from "../actions/pagination-actions";

function* getSprintStudents({ payload }) {

    yield put({ type: GET_SPRINT_STUDENTS_REQUEST })

    let response = yield call(sprintStudentsApi.getStudentsBySprints, payload.query)

    const count = response.data.count
    const isFilter = payload.isFilter

    const accounts = {
        accounts: response.data.results.map(student=>{
            return student.account
        })
    }

    if(response.status === 200) {

        response = yield call(sprintStudentsApi.getAccountsByIds, accounts)

        if(response.status === 200) {
            yield put({
                type: GET_SPRINT_STUDENTS_SUCCESS,
                payload: {students: response.data, count: count, isFilter: isFilter}
            })
            yield put({ type: SET_STUDENTS_PAGINATION_TOTAL_RECORDS, payload: count })
        } else if (response.status === 404) {
            yield put({ type: GET_SPRINT_STUDENTS_SUCCESS, payload: [] })
        } else {
            yield put({ type: GET_SPRINT_STUDENTS_ERROR })
        }

    } else if (response.status === 404) {
        yield put({ type: GET_SPRINT_STUDENTS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_SPRINT_STUDENTS_ERROR })
    }
}

function* getStudentsForEnroll({ payload }) {

    yield put({ type: GET_SPRINT_STUDENTS_REQUEST })

    const response = yield call(sprintStudentsApi.getStudentsForEnroll, payload.query)
    const isFilter = payload.isFilter

    if(response.status === 200) {
        yield put({
            type: GET_SPRINT_STUDENTS_SUCCESS,
            payload: {students: response.data.results, count: response.data.count, isFilter: isFilter}
        })
        yield put({ type: SET_STUDENTS_PAGINATION_TOTAL_RECORDS, payload: response.data.count })
    } else if (response.status === 404) {
        yield put({ type: GET_SPRINT_STUDENTS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_SPRINT_STUDENTS_ERROR })
    }
}

function* enrollStudents({ payload }){

    yield put({ type: ENROLL_STUDENTS_REQUEST })

    let response = yield call(sprintStudentsApi.enrollStudents, payload)

    const  updateData = {
        data: {enrolled: true},
        id: payload.sprint
    }

    if (response.status === 200) {
        yield put({ type: ENROLL_STUDENTS_SUCCESS })
        yield put({ type: UPDATE_SPRINT, payload: updateData });
    }

    if(response.status >=400 && response.status < 600){
        yield put({ type: ENROLL_STUDENTS_ERROR })
    }
}

function* enrollStudent({ payload }){

    yield put({ type: ENROLL_STUDENT_REQUEST })

    let response = yield call(sprintStudentsApi.enrollStudent, payload)

    const data = response.data

    if (response.status === 200) {
        yield call(getSprintStudents, {payload: {query: {sprint_id: payload.sprintId}}})
        yield put({ type: ENROLL_STUDENT_SUCCESS, payload: data })
    } else {
        data["code"] = response.status
        yield put({ type: ENROLL_STUDENT_ERROR, payload: data })
    }
}

function* unEnrollStudent({ payload }){

    yield put({ type: UNENROLL_STUDENT_REQUEST })

    let response = yield call(sprintStudentsApi.unEnrollStudent, payload)

    const data = response.data

    if (response.status === 200) {
        yield call(getSprintStudents, {payload: {query: {sprint_id: payload.sprintId}}})
        yield put({ type: UNENROLL_STUDENT_SUCCESS, payload: data })
    } else {
        data["code"] = response.status
        yield put({ type: UNENROLL_STUDENT_ERROR, payload: data })
    }
}

export default function* sprintStudentsSaga() {
    yield takeEvery(GET_SPRINT_STUDENTS, getSprintStudents)
    yield takeEvery(GET_STUDENTS_FOR_ENROLL, getStudentsForEnroll)
    yield takeLatest(ENROLL_STUDENTS, enrollStudents)
    yield takeLatest(ENROLL_STUDENT, enrollStudent)
    yield takeLatest(UNENROLL_STUDENT, unEnrollStudent)
}
