import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {SHOW_MODAL} from "../redux/actions/common-actions";
import {connect} from "react-redux";
import Stat from "../components/elements/Stat";
import StudentsTable from "../components/Students/StudentsTable";
import {GET_STUDENTS, RESET_STUDENTS_DATA} from "../redux/actions/students-actions";
import Pagination from "../components/elements/pagination/Pagination";
import Loading from "../components/Loading";

const StudentsContainer = ({ resetStudentsData, loadingStudents, totalRecords, pageSize, getStudents }) => {

    const [searchValue, setSearchValue] = useState('')
    const [filterValue, setFilterValue] = useState('')

    useEffect(() => {
        return () => {
            resetStudentsData()
        }
    }, []);

    const handleSearchValue = (event) => {
        setSearchValue(event.target.value)
    }

    const handleFilterValue = (event) => {
        setFilterValue(event.target.value)
        getStudentsWithParam(1, pageSize, event.target.value, searchValue)
    }

    const searchFetch = () => {
        getStudentsWithParam(1, pageSize, filterValue, searchValue)
    }

    const onPageChanged = data => {
        const { currentPage, pageLimit } = data;
        getStudentsWithParam(currentPage, pageLimit, filterValue, searchValue)
    }

    function getStudentsWithParam(pageNumber, pageLimit, profileStatus, search) {
        const query = {
            'search': search,
            'profile__status': profileStatus,
            'is_student': true,
            'page_size': pageLimit,
            'page': pageNumber
        }

        getStudents(query)
    }

    return (
        <>
            <div className="flex flex-col drawer-content">
                <h1 className="prose-2xl mb-2">Stat</h1>
                <div className="w-full shadow stats mb-10">
                    <Stat />
                    <Stat />
                    <Stat />
                </div>
                <div className={"flex flex-row items-center"}>
                    <h1 className="prose-2xl mb-2 mr-3">Students</h1>
                </div>
                <div className={"flex flex-row justify-start items-end mb-3"}>
                    <div className="form-control">
                        <div className="input-group">
                            <input
                                type="text"
                                placeholder="Search…"
                                className="input input-bordered"
                                onChange={handleSearchValue}
                            />
                            <button
                                className={`btn btn-square`}
                                onClick={searchFetch}
                            >
                                {loadingStudents ? <Loading size="xs" /> : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="form-control w-full max-w-xs ml-4">
                        <span className="label-text">Filter by status</span>
                        <select
                            defaultValue={''}
                            className="select select-bordered"
                            onChange={handleFilterValue}
                        >
                            <option disabled>Filter by status</option>
                            <option value={''}>All</option>
                            <option value={'active'}>Active</option>
                            <option value={'freeze'}>Freeze</option>
                        </select>
                    </div>
                </div>

                <StudentsTable />

                <div className="flex flex-grow-1 justify-center mt-4">
                    <Pagination
                        totalRecords={totalRecords}
                        pageLimit={pageSize}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                    />
                </div>
            </div>
        </>

    );
}

StudentsContainer.propTypes = {
    modal: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    loadingStudents: PropTypes.bool,
    resetStudentData: PropTypes.func,
    getStudents: PropTypes.func.isRequired,
    studentsTotalRecords: PropTypes.number,
    pageSize: PropTypes.number
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    loadingStudents: state.students.loadingStudents,
    totalRecords: state.pagination.studentsTotalRecords,
    pageSize: state.pagination.studentsPageSize
})


const mapDispatchToProps = (dispatch) => ({
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
    getStudents: (query) => dispatch({ type: GET_STUDENTS, payload: query }),
    resetStudentsData: () => dispatch({ type: RESET_STUDENTS_DATA })
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentsContainer)
