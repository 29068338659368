import Preview from "../Preview/index";
import React from "react";

const Device = () => {

    return (
        <>
            <h1 className={"my-10 text-2xl"}>Preview</h1>
            <div className="mockup-phone top-0 left-0">
                <div className="camera" />
                <div className="display">
                    <Preview />
                </div>
            </div>
        </>
    );
};

export default Device;
