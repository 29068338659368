import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withUseFormHook} from "../../../helpers/with-form-hook";

import {
    CLEAR_CREATE_FTF_ERROR,
    CREATE_FTF,
} from "../../../redux/actions/ftf-actions";
import {HIDE_MODAL} from "../../../redux/actions/common-actions";
import Loading from "../../Loading";

class CreateFtfTaskModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.loadingCreateFtf && !this.props.loadingCreateFtf && this.props.createFtfError === null ) {
            this.props.hideModal('createFtf')
        }
    }

    onSubmit = (data) => {
        data.sprint = this.props.currentSprint.id
        this.props.createFtf({
            ftf: data,
            sprint: this.props.currentSprint.id
        })
    }

    onCancel = (e) => {
        e.preventDefault()
        this.props.hideModal('createFtf')
        this.props.clearCreateFtfError()
    }

    render() {
        const modalStyle = this.props.modal ? 'modal modal-open' : "modal"
        const { register, handleSubmit, formState: { errors } } = this.props.form;
        let loading = this.props.loadingCreateFtf
        let error = this.props.createFtfError

        return (
            <React.Fragment>
                <div className={modalStyle}>
                    <div className="modal-box max-w-screen-lg max-h-screen-lg">
                        <div className={"text-xl font-bold"}>Create FTF Task</div>
                        <div className="divider dark" />
                        <div className={"my-5"}>
                            <form className="">
                                <div className="form-control flex-1 mx-2">
                                    <label className="label">
                                        <span className="label-text">Title</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder="Title for FTF task"
                                        className={`input input-bordered ${errors.title && 'input-error'}`}
                                        {...register("title", { required: true })}
                                    />
                                    <label className="label">
                                        {errors.title && <span className="label-text-alt">Data is incorrect</span>}
                                    </label>
                                </div>
                                <div className="flex flex-row mt-5 ml-2 mr-4">
                                    <div className="flex flex-col flex-1">
                                        <h2>Student A</h2>
                                        <div className="form-control flex-1">
                                            <label className="label">
                                                <span className="label-text">Task</span>
                                            </label>
                                            <textarea
                                                name="taskA"
                                                placeholder="Task description for student A"
                                                className={`textarea h-24 textarea-bordered ${errors.taskA && 'textarea-error'}`}
                                                {...register("taskA", { required: true })}
                                            />
                                            <label className="label">
                                                {errors.taskA && <span className="label-text-alt">Data is incorrect</span>}
                                            </label>
                                        </div>
                                        <div className="form-control flex-1">
                                            <label className="label">
                                                <span className="label-text">Role</span>
                                            </label>
                                            <textarea
                                                name="roleA"
                                                placeholder="Role description for student A"
                                                className={`textarea h-24 textarea-bordered ${errors.roleA && 'textarea-error'}`}
                                                {...register("roleA", { required: true })}
                                            />
                                            <label className="label">
                                                {errors.roleA && <span className="label-text-alt">Data is incorrect</span>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="divider divider-vertical" />
                                    <div className="flex flex-col flex-1 mb-2 ml-4">
                                        <h2>Student B</h2>
                                        <div className="form-control flex-1">
                                            <label className="label">
                                                <span className="label-text">Task</span>
                                            </label>
                                            <textarea
                                                name="taskB"
                                                placeholder="Task description for student B"
                                                className={`textarea h-24 textarea-bordered ${errors.taskB && 'textarea-error'}`}
                                                {...register("taskB", { required: true })}
                                            />
                                            <label className="label">
                                                {errors.taskB && <span className="label-text-alt">Data is incorrect</span>}
                                            </label>
                                        </div>
                                        <div className="form-control flex-1">
                                            <label className="label">
                                                <span className="label-text">Role</span>
                                            </label>
                                            <textarea
                                                name="roleB"
                                                placeholder="Role description for student B"
                                                className={`textarea h-24 textarea-bordered ${errors.roleB && 'textarea-error'}`}
                                                {...register("roleB", { required: true })}
                                            />
                                            <label className="label">
                                                {errors.roleB && <span className="label-text-alt">Data is incorrect</span>}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="divider dark" />
                        {error ? (
                            <div className="alert shadow-lg alert-error my-4">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
                                         fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                    <span className="ml-4">Error! { error }.</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="modal-action">
                            <button
                                onClick={handleSubmit(this.onSubmit)}
                                className={`btn btn-primary ${loading ? 'loading' : ''}`}
                            >
                                {loading && <Loading size="xs" />}
                                Submit
                            </button>
                            <button onClick={this.onCancel} className="btn">Close</button>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

CreateFtfTaskModal.propTypes = {
    form: PropTypes.any,
    currentSprint: PropTypes.object.isRequired,
    loadingCreateFtf: PropTypes.bool,
    createFtfError: PropTypes.string,
    hideModal: PropTypes.func.isRequired,
    createFtf: PropTypes.func.isRequired,
    clearCreateFtfError: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    currentSprint: state.sprint.currentSprint,
    loadingCreateFtf: state.ftf.loadingCreateFtf,
    createFtfError: state.ftf.createFtfError,
    modal: state.common.modal
})

const mapDispatchToProps = (dispatch) => ({
    createFtf: (ftf) => dispatch({ type: CREATE_FTF, payload: ftf }),
    hideModal: (modalType) => dispatch({ type: HIDE_MODAL, payload: modalType }),
    clearCreateFtfError: () => dispatch({ type: CLEAR_CREATE_FTF_ERROR }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(CreateFtfTaskModal))
