import React from "react";
import SprintsTable from "../components/Sprints/SprintsTable";
import PropTypes from "prop-types";
import {SHOW_MODAL} from "../redux/actions/common-actions";
import {connect} from "react-redux";
import Stat from "../components/elements/Stat";
import {GET_CATEGORIES, GET_SPRINTS} from "../redux/actions/sprint-actions";
import Pagination from "../components/elements/pagination/Pagination";

const SprintsContainer = ({ showModal, getCategories, totalRecords, pageSize, getSprints }) => {

    const openModal = () => {
        showModal('createSprint')
        getCategories()
    }

    const onPageChanged = data => {
        const { currentPage, pageLimit } = data;
        getSprintsWithParam(currentPage, pageLimit)
    }

    function getSprintsWithParam(pageNumber, pageLimit) {
        const query = {
            'page_size': pageLimit,
            'page': pageNumber
        }

        getSprints(query)
    }

    return (
        <React.Fragment>
            <h1 className="prose-2xl mb-2">Stat</h1>
            <div className="shadow stats">
                <Stat />
                <Stat />
                <Stat />
            </div>
            <div className={"flex flex-row items-center mt-10 mb-2"}>
                <h1 className="prose-2xl mr-3">Sprints</h1>
                <button onClick={openModal} className="btn btn-primary btn-xs">Add sprint</button>
            </div>
            <div className={"grow"}>
                <SprintsTable />
            </div>


            <div className="flex justify-center mt-4">
                <Pagination
                    totalRecords={totalRecords}
                    pageLimit={pageSize}
                    pageNeighbours={1}
                    onPageChanged={onPageChanged}
                />
            </div>
        </React.Fragment>
    );
}

SprintsContainer.propTypes = {
    modal: PropTypes.bool.isRequired,
    getCategories: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    modal: state.common.modal,
    pageSize: state.pagination.sprintsPageSize,
    totalRecords: state.pagination.sprintsTotalRecords,
})


const mapDispatchToProps = (dispatch) => ({
    showModal: (modalType) => dispatch({ type: SHOW_MODAL, payload: modalType }),
    getCategories: () => dispatch({ type: GET_CATEGORIES }),
    getSprints: (query) => dispatch({ type: GET_SPRINTS, payload: query }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SprintsContainer)
