import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {GET_SPRINT_STUDENTS} from "../../../../redux/actions/sprint-students-actions";
import {withRouter} from "../../../../helpers/with-router";

import {
    flexRender,
    getSortedRowModel,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'
import moment from "moment/moment";

import {
    TiArrowUnsorted,
    TiArrowSortedDown,
    TiArrowSortedUp
} from "react-icons/ti";
import UnEnrollStudentButton from "./UnEnrollStudentButton";

const StudentsTable = ({ students, loadingSprintStudents, getSprintStudentsError, sprint }) => {

    const columns = [
        {
            id: 'instagram',
            cell: props => {
                const instagram = props.row.original.profile.instagram
                const uid = props.row.original.uid
                return <Link className={"link link-neutral"} to={`/students/${uid}`}>{instagram}</Link>
            },
            header: () => <span>Instagram</span>,
            footer: () => <span>Instagram</span>,
        },
        {
            id: 'balance',
            accessorFn: row => `${row.balance.weeks}`,
            header: () => <span>Balance</span>,
            footer: () => <span>Balance</span>,
        },
        {
            id: 'lastPaymentDate',
            accessorFn: row => {
                if(row.balance.lastPaymentDate){
                    return `${moment(row.balance.lastPaymentDate).format("DD-MM-YYYY HH:mm")}`
                }
                return ''
            },
            header: () => <span>Last Payment Date</span>,
            footer: () => <span>Last Payment Date</span>,
        },
        {
            id: 'action',
            cell: props => {
                const uid = props.row.original.uid
                return (
                    <UnEnrollStudentButton
                        student={props.row.original}
                        sprint={sprint}
                    />
                )
            },
            header: () => <span>Actions</span>,
            footer: () => <span>Actions</span>,
        },
    ]

    const [data, setData] = useState([])
    const [sorting, setSorting] = useState([])

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })


    useEffect(()=>{
        if(students){
            setData(() => [...students])
        }
    }, [students])

    return (
        <React.Fragment>
            <div className="overflow-x-auto">
                <table className="table w-full">
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <div
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none flex flex-row items-center'
                                                        : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                { header.column.getCanSort() ?
                                                    {asc: <TiArrowSortedUp className={"w-4 h-4"} />,desc: <TiArrowSortedDown className={"w-4 h-4"} />,}
                                                        [header.column.getIsSorted()] ?? <TiArrowUnsorted className={"w-4 h-4"}/> : ''
                                                }
                                            </div>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                    {table.getFooterGroups().map(footerGroup => (
                        <tr key={footerGroup.id}>
                            {footerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.footer,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </tfoot>
                </table>
            </div>
        </React.Fragment>
    );

}

StudentsTable.propTypes = {
    loadingSprintStudents: PropTypes.bool,
    params: PropTypes.any,
    getSprintStudentsError: PropTypes.string,
    students: PropTypes.array,
    getSprintStudents: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    loadingSprintStudents: state.sprintStudents.loadingSprintStudents,
    getSprintStudentsError: state.sprintStudents.getSprintStudentsError,
    currentSprint: state.sprint.currentSprint,
    students: state.sprintStudents.students,
})

const mapDispatchToProps = (dispatch) => ({
    getSprintStudents: (sprintId) => dispatch({ type: GET_SPRINT_STUDENTS,  payload: sprintId })
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StudentsTable))
