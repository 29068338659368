import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Stat from "../components/elements/Stat";
import ChallengeCard from "../components/Challenges/ChallengeCard";
import PropTypes from "prop-types";
import {GET_CHALLENGES} from "../redux/actions/challenge-actions";

const ChallengesContainer = ({ challenges, getChallenges, loadingChallenges }) => {

    useEffect(()=>{
        getChallenges()
    },[])

    return (
        <div className="flex flex-col drawer-content">
            {/*<h1 className="prose-2xl mb-2">Stat</h1>*/}
            {/*<div className="w-full shadow stats mb-10">*/}
            {/*    <Stat />*/}
            {/*    <Stat />*/}
            {/*    <Stat />*/}
            {/*</div>*/}
            <div className={"flex flex-row items-center mb-2"}>
                <h1 className="prose-2xl mr-3">Challenges</h1>
            </div>
            <div className={"flex flex-row flex-wrap gap-5"}>
                {loadingChallenges ? (
                    <div>LOADING</div>
                ) : (
                     challenges && challenges.map(challenge=>{
                        return <ChallengeCard key={challenge.id} challenge={challenge} />
                    })
                )}
            </div>
        </div>
    );
}

ChallengesContainer.propTypes = {
    challenges: PropTypes.array,
    loadingChallenges: PropTypes.bool,
    getChallenges: PropTypes.func
}

const mapStateToProps = (state) => ({
    challenges: state.challenge.challenges
})


const mapDispatchToProps = (dispatch) => ({
    getChallenges: () => dispatch({ type: GET_CHALLENGES })
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengesContainer)
